


















































































































































import { Subscription } from 'rxjs';
import { skipWhile, tap } from 'rxjs/operators';
import { Component, Vue } from 'vue-property-decorator';

import { ButtonStatusTypes, PanelHeaderStatusTypes } from '@/services/common/ui.service';
import { IModel } from '@/services/common/form-validate.service';

import {
	BalanceRequestTypes,
	IBalanceRequest,
	IListBalanceRequestDto,
	IListBalanceRequestPayload
} from '@/services/api/balance-request-api.service';

import { UserRoles } from '@/services/payloads/user.payload';
import { IRequestPayload } from '@/services/dtos/shared.dto';

import WbPaymentHistory from '../../../_components/wb-payment-history/wb-payment-history.vue';
import WbGrantBalanceRequestModal from '../modals/wb-grant-balance-request-modal/wb-grant-balance-request-modal.vue';
import WbRejectBalanceRequestModal from '../modals/wb-reject-balance-request-modal/wb-reject-balance-request-modal.vue';

interface ISubscriptions {
	listUsersOrders: Subscription | null;
	topUp: Subscription | null;
	requestResult: Subscription | null;
}

@Component({
	name : 'wb-admin-orders',
	components : {
		WbPaymentHistory,
		WbRejectBalanceRequestModal,
		WbGrantBalanceRequestModal,
	},
})
export default class WbAdminOrders extends Vue {
	public panelHeaderStatusTypes = PanelHeaderStatusTypes;
	public buttonStatusTypes = ButtonStatusTypes;

	private subscriptions: ISubscriptions = {
		listUsersOrders : null,
		topUp : null,
		requestResult : null,
	};

	public filterModel: IModel = {
		fields : {
			search : {
				placeholder : 'Search name',
				oldValue : '',
				value : '',
				required : false,
				focus : false,
				status : {},
				error : {},
			},
			select : {
				placeholder : 'Selection',
				oldValue : '-',
				value : '-',
				required : false,
				options : [
					{ value : '-', label : 'Show all', },
					{ value : 'PENDING', label : 'Show PENDING', },
					{ value : 'GRANTED', label : 'Show GRANTED', },
					{ value : 'REJECTED', label : 'Show REJECTED', }
				],
				focus : false,
				status : {},
				error : {},
			},
		},
		properties : {
			dirtyFields : [],
		},
	};

	public paginationOptions = {
		min : 1,
		current : 1,
		max : 1,
		pageSize : 25,
	};

	public balanceRequests: IBalanceRequest[] = [];
	public selectedRequest: IBalanceRequest | null = null;
	public selectedUserRowId: number | null = null;

	public modal = {
		grant : false,
		reject : false,
	};

	public langRoles = {
		0 : 'Super Admin',
		1 : 'Admin',
		2 : 'User',
	}

	/**
	 * CREATED
	 */
	public created(): void {
		this.subscribeToStore();
		this.listUsersOrders();

		// eslint-disable-next-line no-console
		console.info('%c CREATE WbAdminOrders ', 'background: blue; color: #FFF');
	}

	public destroyed(): void {
		this.unsubscribeFromStore();

		// eslint-disable-next-line no-console
		console.info('%c DESTROY WbAdminOrders ', 'background: purple; color: #FFF');
	}

	public listUsersOrders(): void {
		const dto: IListBalanceRequestDto = {
			page : this.paginationOptions.current,
			pageLength : this.paginationOptions.pageSize,
			search : this.filterModel.fields.search.value.toString(),
		};
		if (this.filterModel.fields.select.value !== '-' && dto) {
			dto.type = this.filterModel.fields.select.value as BalanceRequestTypes;
		}
		this.$services.balance.listBalanceRequest(dto);
	}

	public onPaginationPageChange(newPageNumber: number): void {
		this.paginationOptions.current = newPageNumber;
		this.listUsersOrders();
	}

	/* Modals */
	public openModalChangeStatusToGranted(balanceRequest: IBalanceRequest): void {
		this.modal.grant = true;
		this.selectedRequest = balanceRequest;
	}

	public openModalChangeStatusToRejected(balanceRequest: IBalanceRequest): void {
		this.modal.reject = true;
		this.selectedRequest = balanceRequest;
	}

	public grant(balanceRequestId: number): void {
		this.$services.balance.changeRequestStatusToGrant({ balanceRequestId : balanceRequestId, });
		this.modal.grant = false;
	}

	public reject(balanceRequestId: number): void {
		this.$services.balance.changeRequestStatusToReject({ balanceRequestId : balanceRequestId, });
		this.modal.reject = false;
	}
	/* Modals */

	private subscribeToStore(): void {
		this.subscriptions.listUsersOrders = this.$services.balance.balanceRequest$
			.pipe(
				tap((listBalanceRequestPayload: IListBalanceRequestPayload | null) => {
					if (listBalanceRequestPayload) {
						this.balanceRequests = listBalanceRequestPayload.balanceRequests;
						this.paginationOptions.max = Math.ceil(listBalanceRequestPayload.count / this.paginationOptions.pageSize);
					}
				})
			).subscribe();

		this.subscriptions.topUp = this.$services.balance.requestResult$
			.pipe(
				skipWhile((requestPayload) => requestPayload === null),
				tap((requestPayload: IRequestPayload | null) => {
					if (requestPayload && requestPayload.actor === 'resendConfirmationEmail') {
						if (requestPayload.status === 'success') {
							// this.closeModalSendConfirmationEmailToUser();
						}
					}
				})
			).subscribe();

		this.subscriptions.requestResult = this.$services.balance.requestResult$
			.pipe(
				skipWhile((requestPayload) => requestPayload === null),
				tap((requestPayload: IRequestPayload | null) => {
					if (requestPayload) {
						if (requestPayload.actor === 'changeStatus') {
							this.listUsersOrders();
						}
					}
				})
			).subscribe();
	}

	private unsubscribeFromStore(): void {
		Object.keys(this.subscriptions).map(key => this.subscriptions[key as keyof ISubscriptions])
			.forEach((subscription: Subscription | null) => {
				if (subscription) {
					subscription.unsubscribe();
				}
			});
	}

	public exchangeRoleNumber(role: UserRoles): 'USER' | 'ADMIN' | 'SUPER ADMIN' | undefined {
		switch (role) {
			case UserRoles.USER :
				return 'USER';
			case UserRoles.ADMIN :
				return 'ADMIN';
			case UserRoles.SUPERADMIN :
				return 'SUPER ADMIN';
		}
	}

	public filterList(filter: string): void {
		this.$formValidation.onInput(this.filterModel, 'select', filter);
		this.listUsersOrders();
	}

	private setSelectedRowUserId(userId: number): void {
		this.selectedUserRowId = this.selectedUserRowId === userId ? null : userId;
	}
}
