
















































































import { Component, Prop, Vue } from 'vue-property-decorator';

import { Subscription } from 'rxjs';
import { skipWhile, tap } from 'rxjs/operators';

import { IModel, InputTypeTypes } from '@/services/common/form-validate.service';
import { ButtonStatusTypes, PanelHeaderStatusTypes } from '@/services/common/ui.service';

import {
	UserRoles,
	IUser,
	IListOneUserPayload
} from '@/services/payloads/user.payload';
import { IRequestPayload } from '@/services/dtos/shared.dto';

interface ISubscriptions {
	listOneUser: Subscription | null;
	editUser: Subscription | null;
}

@Component({
	name : 'wb-edit-user-modal',
	components : {
	},
})
export default class WbEditUserModal extends Vue {
	public userRoles = UserRoles;

	@Prop({ default : {}, })
	public data!: IUser;

	public panelHeaderStatusTypes = PanelHeaderStatusTypes;
	public buttonStatusTypes = ButtonStatusTypes;

	private subscriptions: ISubscriptions = {
		listOneUser : null,
		editUser : null,
	};

	public model: IModel = {
		fields : {
			organization : {
				oldValue : '',
				value : '',
				placeholder : 'Company Name',
				required : true,
				status : {},
				focus : false,
				error : {
					required : 'Company name is required.',
				},
			},
			email : {
				oldValue : '',
				value : '',
				type : InputTypeTypes.email,
				placeholder : 'Email address',
				required : true,
				status : {},
				focus : false,
				error : {
					required : 'Email address is required.',
					email : 'This is not an email address',
				},
			},
			firstname : {
				oldValue : '',
				value : '',
				placeholder : 'First name',
				required : true,
				status : {},
				focus : false,
				error : {
					required : 'First name is required.',
				},
			},
			lastname : {
				oldValue : '',
				value : '',
				placeholder : 'Last name',
				required : true,
				status : {},
				focus : false,
				error : {
					required : 'Last name is required.',
				},
			},
		},
		properties : {
			dirtyFields : [],
		},
		responseError : null,
	};

	public isBusy = false;

	/**
	 * CREATED
	 */
	public created(): void {
		this.extendModelForSuperAdmin();
		this.resetStateAndSubscribeToStore();
		this.$services.user.listOneUser({ id : this.data.id, });

		// eslint-disable-next-line no-console
		console.info('%c CREATE WbEditUserModal ', 'background: blue; color: #FFF');
	}

	public destroyed(): void {
		this.unsubscribeFromStore();

		// eslint-disable-next-line no-console
		console.info('%c DESTROY WbEditUserModal ', 'background: purple; color: #FFF');
	}

	private extendModelForSuperAdmin() {
		this.$set(this.model.fields, 'role', {
			oldValue : '',
			value : '',
			required : true,
			status : {},
			focus : false,
			error : {
				required : 'Select a role',
			},
			options : [
				{ value : '', label : '-- Select a role --', },
				{ value : UserRoles.USER, label : 'User', },
				{ value : UserRoles.ADMIN, label : 'Admin', },
				{ value : UserRoles.SUPERADMIN, label : 'Super Admin', }
			],
		});
	}

	public edit(): void {
		if (this.model.properties.dirtyFields.length) {
			const validation = this.$formValidation.validate(this.model);

			if (validation.ok()) {
				validation.modifiedCurrResult.id = this.data.id;
				this.$services.user.modifyUser(validation.modifiedCurrResult);
				this.isBusy = true;
			}
		}
	}

	private resetStateAndSubscribeToStore(): void {
		this.$services.user.resetRequestResult(); // !!! oder

		this.subscriptions.listOneUser = this.$services.user.selectedUser$
			.pipe(
				tap((selectedUserPayload: IListOneUserPayload | null) => {
					if (selectedUserPayload) {
						const fields = this.model.fields;
						fields.organization.value = fields.organization.oldValue = selectedUserPayload.organization;
						fields.email.value = fields.email.oldValue = selectedUserPayload.email;
						fields.firstname.value = fields.firstname.oldValue = selectedUserPayload.firstname;
						fields.lastname.value = fields.lastname.oldValue = selectedUserPayload.lastname;

						if (fields.role && selectedUserPayload.role) {
							fields.role.value = fields.role.oldValue = '' + selectedUserPayload.role;
						}
					}
				})
			).subscribe();

		this.subscriptions.editUser = this.$services.user.requestResult$
			.pipe(
				skipWhile((requestPayload) => requestPayload === null),
				tap((requestPayload: IRequestPayload | null) => {
					if (requestPayload) {
						if (requestPayload.actor === 'modifyUser') {
							this.$emit('close-modal');
						}
					}

					this.isBusy = false;
				})
			).subscribe();
	}

	private unsubscribeFromStore(): void {
		Object.keys(this.subscriptions).map(key => this.subscriptions[key as keyof ISubscriptions])
			.forEach((subscription: Subscription | null) => {
				if (subscription) {
					subscription.unsubscribe();
				}
			});
	}
}
