









































































































































import { Component, Vue } from 'vue-property-decorator';

import { skipWhile, tap } from 'rxjs/operators';
import { Subscription } from 'rxjs';

import { IModel } from '@/services/common/form-validate.service';
import { PanelHeaderStatusTypes } from '@/services/common/ui.service';

import { IApp } from '@/services/app-and-firmware-store.service';
import { IListAppsDto, IListAppsPayload } from '@/services/api/app-and-firmware-api.service';

import { IRequestPayload } from '@/services/dtos/shared.dto';

import WbFileDownloader from '@/components/ui-elements/wb-file-downloader/wb-file-downloader.vue';
import WbEnableAppModal from './modals/wb-enable-app-modal/wb-enable-app-modal.vue';
import WbDisableAppModal from './modals/wb-disable-app-modal/wb-disable-app-modal.vue';
import WbEditAppModal from './modals/wb-edit-app-modal/wb-edit-app-modal.vue';

interface ISubscriptions {
	listApps: Subscription | null;
	requestResult: Subscription | null;
}

@Component({
	name : 'wb-admin-apps',
	components : {
		WbFileDownloader,
		WbDisableAppModal,
		WbEnableAppModal,
		WbEditAppModal,
	},
})
export default class WbAdminApps extends Vue {
	public panelHeaderStatusTypes = PanelHeaderStatusTypes;

	public modelApps: IModel = {
		fields : {
			showDisabled : {
				oldValue : false,
				value : false,
				placeholder : 'ShowDisabled',
				required : true,
				status : {},
				focus : false,
				error : {
					required : 'ShowDisabled is required.',
				},
			},
		},
		properties : {
			dirtyFields : [],
		},
		responseError : null,
	};

	public apps: IApp[] = [];

	public modal = {
		disableApp : false,
		enableApp : false,
		editApp : false,
	}

	public filterModel: IModel = {
		fields : {
			search : {
				placeholder : 'Search name',
				oldValue : '',
				value : '',
				required : false,
				focus : false,
				status : {},
				error : {},
			},
			select : {
				placeholder : 'Selection',
				oldValue : '-',
				value : '-',
				required : false,
				options : [
					{ value : '-', label : 'Show all', },
					{ value : 'RC', label : 'Show RC', },
					{ value : 'RELEASE', label : 'Show Release', }
				],
				focus : false,
				status : {},
				error : {},
			},
		},
		properties : {
			dirtyFields : [],
		},
	};

	public selectedId = -1;
	public selectedApp: IApp | null = null;

	private subscriptions: ISubscriptions = {
		listApps : null,
		requestResult : null,
	}

	public paginationOptions = {
		min : 1,
		current : 1,
		max : 1,
		pageSize : 25,
	};

	/**
	 * CREATED
	 */
	public created(): void {
		this.subscribeToStore();
		this.listApps();

		// eslint-disable-next-line no-console
		console.info('%c CREATE WbAdminAppsAndFirmwares.WbAdminApps ', 'background: blue; color: #FFF');
	}

	public destroyed(): void {
		this.unsubscribeFromStore();

		// eslint-disable-next-line no-console
		console.info('%c DESTROY WbAdminAppsAndFirmwares.WbAdminApps ', 'background: purple; color: #FFF');
	}

	public syncAppsAndFirmwares(): void {
		this.$services.appAndFirmware.syncAppsAndFirmwares();
	}

	public openEnableAppModal(appId: number): void {
		this.selectedId = appId;
		this.modal.enableApp = true;
	}

	public openDisableAppModal(appId: number): void {
		this.selectedId = appId;
		this.modal.disableApp = true;
	}

	public openEditAppModal(app: IApp): void {
		this.modal.editApp = true;
		this.selectedApp = app;
	}

	public closeEditAppModal(): void {
		this.modal.editApp = false;
		this.listApps();
	}

	public enableApp(appId: number): void {
		this.$services.appAndFirmware.enableApp(appId);
		this.modal.enableApp = false;
	}

	public disableApp(appId: number): void {
		this.$services.appAndFirmware.disableApp(appId);
		this.modal.disableApp = false;
	}

	public showDisabledApps(newValue: boolean): void {
		this.$formValidation.onInput(this.modelApps, 'showDisabled', newValue);
		this.listApps();
	}

	private subscribeToStore(): void {
		this.subscriptions.listApps = this.$services.appAndFirmware.apps$
			.pipe(
				tap((apps: IListAppsPayload | null) => {
					if (apps) {
						this.apps = apps.flashers.sort((a: IApp, b: IApp) => {
							return +b.time - +a.time;
						});

						this.apps.forEach((app: IApp) => {
							this.$set(app, 'busy', false);
							app.url = `/api/app-and-firmware/download/flasher/${app.nexusAssetId}/${app.name}`;
						});
						this.paginationOptions.max = Math.ceil(apps.count / this.paginationOptions.pageSize);
					}
				})
			).subscribe();

		this.subscriptions.requestResult = this.$services.appAndFirmware.requestResult$
			.pipe(
				skipWhile((requestPayload) => requestPayload === null),
				tap((requestPayload: IRequestPayload | null) => {
					if (requestPayload) {
						if (requestPayload.actor === 'enableApp' || requestPayload.actor === 'disableApp') {
							this.listApps();
						}
					}
				})
			).subscribe();
	}

	private unsubscribeFromStore(): void {
		Object.keys(this.subscriptions).map(key => this.subscriptions[key as keyof ISubscriptions])
			.forEach((subscription: Subscription | null) => {
				if (subscription) {
					subscription.unsubscribe();
				}
			});
	}

	private listApps(): void {
		const dto: IListAppsDto = {
			page : +this.paginationOptions.current,
			pageLength : this.paginationOptions.pageSize,
			showDisabled : (this.modelApps.fields.showDisabled.value === true),
			search : this.filterModel.fields.search.value.toString(),
		};
		if (this.filterModel.fields.select.value === 'RC') {
			dto.showOnlyRc = true;
		} else if (this.filterModel.fields.select.value === 'RELEASE') {
			dto.showOnlyRelease = true;
		}
		this.$services.appAndFirmware.listApps(dto);
	}

	public filterList(filter: string): void {
		this.$formValidation.onInput(this.filterModel, 'select', filter);
		this.listApps();
	}

	public onPaginationPageChange(newPageNumber: number): void {
		this.paginationOptions.current = newPageNumber;
		this.listApps();
	}
}
