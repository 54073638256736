











































































import { Subscription } from 'rxjs';
import { skipWhile, tap } from 'rxjs/operators';
import { Component, Prop, Vue } from 'vue-property-decorator';

import { ButtonStatusTypes, PanelHeaderStatusTypes } from '@/services/common/ui.service';
import { IModel } from '@/services/common/form-validate.service';

import {
	IAndroidFirmware,
	AndroidFirmwareType
} from '@/services/app-and-firmware-store.service';
import { IListOneAndroidFirmwarePayload } from '@/services/api/android-firmware-api.service';
import { IRequestPayload } from '@/services/dtos/shared.dto';

interface ISubscriptions {
	listOneAndroidFirmware: Subscription | null;
	editAndroidFirmware: Subscription | null;
}

@Component({
	name : 'wb-edit-android-firmware-modal',
	components : {
	},
})
export default class WbEditAndroidFirmwareModal extends Vue {
	@Prop({ default : {}, })
	public data!: IAndroidFirmware;

	public panelHeaderStatusTypes = PanelHeaderStatusTypes;
	public buttonStatusTypes = ButtonStatusTypes;

	private subscriptions: ISubscriptions = {
		listOneAndroidFirmware : null,
		editAndroidFirmware : null,
	};

	public model: IModel = {
		fields : {
			name : {
				oldValue : '',
				value : '',
				placeholder : 'Name',
				required : true,
				status : {},
				focus : false,
				error : {
					required : 'Name is required.',
				},
			},
			version : {
				oldValue : '',
				value : '',
				placeholder : 'Version',
				required : true,
				status : {},
				focus : false,
				error : {
					required : 'Version is required.',
				},
			},
			description : {
				oldValue : '',
				value : '',
				placeholder : 'Description',
				required : false,
				status : {},
				focus : false,
				error : {
					required : 'Description is required.',
				},
			},
			isLatest : {
				oldValue : false,
				value : false,
				placeholder : 'Is Latest',
				required : false,
				status : {},
				focus : false,
				error : {
					required : 'Is Latest is required.',
				},
			},
			type : {
				oldValue : '',
				value : '',
				placeholder : 'Type',
				required : false,
				status : {},
				focus : false,
				error : {
					required : 'Type is required.',
				},
				options : [
					{ value : '', label : '-- Select a device type --', },
					{ value : AndroidFirmwareType.AI, label : 'AI', },
					{ value : AndroidFirmwareType.DN_73, label : 'DN-73', },
					{ value : AndroidFirmwareType.DN_73_STOCK, label : 'DN-73-STOCK', },
					{ value : AndroidFirmwareType.DN_74, label : 'DN-74', },
					{ value : AndroidFirmwareType.DN_74_STOCK, label : 'DN-74-STOCK', },
					{ value : AndroidFirmwareType.DN_75, label : 'DN-75', },
					{ value : AndroidFirmwareType.DN_75_STOCK, label : 'DN-75-STOCK', },
					{ value : AndroidFirmwareType.DN_76, label : 'DN-76', }
				],
			},
		},
		properties : {
			dirtyFields : [],
		},
		responseError : null,
	};

	public isBusy = false;

	/**
	 * CREATED
	 */
	public created(): void {
		this.resetStateAndSubscribeToStore();
		this.$services.appAndFirmware.listOneAndroidFirmware({
			id : this.data.id,
		});

		// eslint-disable-next-line no-console
		console.info('%c CREATE WbEditAndroidFirmwareModal ', 'background: blue; color: #FFF');
	}

	public destroyed(): void {
		this.unsubscribeFromStore();

		// eslint-disable-next-line no-console
		console.info('%c DESTROY WbEditAndroidFirmwareModal ', 'background: purple; color: #FFF');
	}

	public edit(): void {
		if (this.model.properties.dirtyFields.length) {
			const validation = this.$formValidation.validate(this.model);

			if (validation.ok()) {
				validation.modifiedCurrResult.id = this.data.id;
				this.$services.appAndFirmware.modifyAndroidFirmware(validation.modifiedCurrResult);
				this.isBusy = true;
			}
		}
	}

	private resetStateAndSubscribeToStore(): void {
		this.$services.appAndFirmware.resetRequestResult(); // !!! oder

		this.subscriptions.listOneAndroidFirmware = this.$services.appAndFirmware.selectedAndroidFirmware$
			.pipe(
				tap((selectedAndroidFirmwarePayload: IListOneAndroidFirmwarePayload | null) => {
					if (selectedAndroidFirmwarePayload) {
						const fields = this.model.fields;
						fields.name.value = fields.name.oldValue = selectedAndroidFirmwarePayload.name;
						fields.version.value = fields.version.oldValue = selectedAndroidFirmwarePayload.version;
						fields.type.value = fields.type.oldValue = selectedAndroidFirmwarePayload.type;
						fields.description.value = fields.description.oldValue = selectedAndroidFirmwarePayload.description;
						fields.isLatest.value = fields.isLatest.oldValue = selectedAndroidFirmwarePayload.isLatest;
					}
				})
			).subscribe();

		this.subscriptions.editAndroidFirmware = this.$services.appAndFirmware.requestResult$
			.pipe(
				skipWhile((requestPayload) => requestPayload === null),
				tap((requestPayload: IRequestPayload | null) => {
					if (requestPayload) {
						if (requestPayload.actor === 'modifyAndroidFirmware') {
							this.$emit('close-modal');
						}
					}

					this.isBusy = false;
				})
			).subscribe();
	}

	private unsubscribeFromStore() {
		Object.keys(this.subscriptions).map(key => this.subscriptions[key as keyof ISubscriptions])
			.forEach((subscription: Subscription | null) => {
				if (subscription) {
					subscription.unsubscribe();
				}
			});
	}
}
