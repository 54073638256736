




























import { Subscription } from 'rxjs';
import { skipWhile, tap } from 'rxjs/operators';
import { Component, Vue } from 'vue-property-decorator';

import { PanelHeaderStatusTypes } from '@/services/common/ui.service';
import { IRequestPayload } from '@/services/dtos/shared.dto';

interface ISubscriptions {
	requestResult: Subscription | null;
	pendingRequests: Subscription | null;
}

@Component({
	name : 'wb-admin-payment-history',
	components : {
	},
})
export default class WbAdminPaymentHistory extends Vue {
	public panelHeaderStatusTypes = PanelHeaderStatusTypes;
	public toggle = false;

	private subscriptions: ISubscriptions = {
		requestResult : null,
		pendingRequests : null,
	}

	public pendingRequests = 0;

	/**
	 * CREATED
	 */
	public created(): void {
		this.subscribeToStore();

		this.$services.balance.getPendingBalanceRequestCount();

		// eslint-disable-next-line no-console
		console.info('%c CREATE WbAdminPaymentHistory ', 'background: blue; color: #FFF');
	}

	public destroyed(): void {
		this.unsubscribeFromStore();

		// eslint-disable-next-line no-console
		console.info('%c DESTROY WbAdminPaymentHistory ', 'background: purple; color: #FFF');
	}

	private subscribeToStore(): void {
		this.subscriptions.pendingRequests = this.$services.balance.pendingBalanceRequestCount$
			.pipe(
				tap((pendingRequestCount: number) => {
					this.pendingRequests = pendingRequestCount;
				})
			).subscribe();

		this.subscriptions.requestResult = this.$services.balance.requestResult$
			.pipe(
				skipWhile((requestPayload) => requestPayload === null),
				tap((requestPayload: IRequestPayload | null) => {
					if (requestPayload) {
						if (requestPayload.actor === 'xxxx') {
						}
						this.$services.balance.getPendingBalanceRequestCount();
					}
				})
			).subscribe();
	}

	private unsubscribeFromStore(): void {
		Object.keys(this.subscriptions).map(key => this.subscriptions[key as keyof ISubscriptions])
			.forEach((subscription: Subscription | null) => {
				if (subscription) {
					subscription.unsubscribe();
				}
			});
	}

	public goTo(state: string): void {
		this.$router.push({ name : 'adminBalance', });
		this.$router.push({ name : state, })
			.catch((err) => {
				// eslint-disable-next-line no-console
				console.info('ROUTER: ', state, err);
			});
	}
}
