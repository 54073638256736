import { render, staticRenderFns } from "./wb-pie-chart.vue?vue&type=template&id=7342a764&scoped=true&"
import script from "./wb-pie-chart.vue?vue&type=script&lang=ts&"
export * from "./wb-pie-chart.vue?vue&type=script&lang=ts&"
import style0 from "./wb-pie-chart.vue?vue&type=style&index=0&id=7342a764&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7342a764",
  null
  
)

export default component.exports