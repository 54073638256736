






























































































































import { Component, Vue } from 'vue-property-decorator';

import { skipWhile, tap } from 'rxjs/operators';
import { Subscription } from 'rxjs';

import { PanelHeaderStatusTypes } from '@/services/common/ui.service';
import { IModel } from '@/services/common/form-validate.service';

import { IFirmware } from '@/services/app-and-firmware-store.service';

import { IListPricesPayload } from '@/services/api/price-group-api.service';
import { IListFirmwaresDto, IListFirmwaresPayload } from '@/services/api/app-and-firmware-api.service';

import { IRequestPayload } from '@/services/dtos/shared.dto';

import WbEnableFirmwareModal from './modals/wb-enable-firmware-modal/wb-enable-firmware-modal.vue';
import WbDisableFirmwareModal from './modals/wb-disable-firmware-modal/wb-disable-firmware-modal.vue';
import WbEditFirmwareModal from './modals/wb-edit-firmware-modal/wb-edit-firmware-modal.vue';

interface ISubscriptions {
	listFirmwares: Subscription | null;
	listPrices: Subscription | null;
	requestResult: Subscription | null;
}

@Component({
	name : 'wb-admin-firmwares',
	components : {
		WbDisableFirmwareModal,
		WbEnableFirmwareModal,
		WbEditFirmwareModal,
	},
})
export default class WbAdminFirmwares extends Vue {
	public panelHeaderStatusTypes = PanelHeaderStatusTypes;

	public modelFirmwares: IModel = {
		fields : {
			showDisabled : {
				oldValue : false,
				value : false,
				placeholder : 'ShowDisabled',
				required : true,
				status : {},
				focus : false,
				error : {
					required : 'ShowDisabled is required.',
				},
			},
		},
		properties : {
			dirtyFields : [],
		},
		responseError : null,
	};

	public firmwares: IFirmware[] = [];
	public prices: {[key: string]: number} = {};

	public modal = {
		disableFirmware : false,
		enableFirmware : false,
		editFirmware : false,
	}

	public filterModel: IModel = {
		fields : {
			search : {
				placeholder : 'Search name',
				oldValue : '',
				value : '',
				required : false,
				focus : false,
				status : {},
				error : {},
			},
			select : {
				placeholder : 'Selection',
				oldValue : '-',
				value : '-',
				required : false,
				options : [
					{ value : '-', label : 'Show all', },
					{ value : 'RC', label : 'Show RC', },
					{ value : 'RELEASE', label : 'Show Release', }
				],
				focus : false,
				status : {},
				error : {},
			},
		},
		properties : {
			dirtyFields : [],
		},
	};

	public selectedId = -1;
	public selectedFirmware: IFirmware | null = null;

	private subscriptions: ISubscriptions = {
		listFirmwares : null,
		listPrices : null,
		requestResult : null,
	}

	public paginationOptions = {
		min : 1,
		current : 1,
		max : 1,
		pageSize : 25,
	};

	/**
	 * CREATED
	 */
	public created(): void {
		this.subscribeToStore();

		this.listFirmwares();

		this.$services.prices.listPrices({
			page : this.paginationOptions.current,
			pageLength : this.paginationOptions.pageSize,
		});

		// eslint-disable-next-line no-console
		console.info('%c CREATE WbAdminAppsAndFirmwares.WbAdminFirmwares ', 'background: blue; color: #FFF');
	}

	public destroyed(): void {
		this.unsubscribeFromStore();

		// eslint-disable-next-line no-console
		console.info('%c DESTROY WbAdminAppsAndFirmwares.WbAdminFirmwares ', 'background: purple; color: #FFF');
	}

	public syncAppsAndFirmwares(): void {
		this.$services.appAndFirmware.syncAppsAndFirmwares();
	}

	public openEnableFirmwareModal(firmwareId: number): void {
		this.selectedId = firmwareId;
		this.modal.enableFirmware = true;
	}

	public openDisableFirmwareModal(firmwareId: number): void {
		this.selectedId = firmwareId;
		this.modal.disableFirmware = true;
	}

	public openModalEditFirmware(firmware: IFirmware): void {
		this.modal.editFirmware = true;
		this.selectedFirmware = firmware;
	}

	public closeModalEditFirmware(): void {
		this.modal.editFirmware = false;
		this.listFirmwares();
	}

	public enableFirmware(firmwareId: number): void {
		this.$services.appAndFirmware.enableFirmware(firmwareId);
		this.modal.enableFirmware = false;
	}

	public disableFirmware(firmwareId: number): void {
		this.$services.appAndFirmware.disableFirmware(firmwareId);
		this.modal.disableFirmware = false;
	}

	public showDisabledFirmwares(newValue: boolean): void {
		this.$formValidation.onInput(this.modelFirmwares, 'showDisabled', newValue);
		this.listFirmwares();
	}

	private subscribeToStore(): void {
		this.subscriptions.listFirmwares = this.$services.appAndFirmware.firmwares$
			.pipe(
				tap((firmwaresPayload: IListFirmwaresPayload | null) => {
					if (firmwaresPayload) {
						this.firmwares = firmwaresPayload.firmwares;
						this.paginationOptions.max = Math.ceil(firmwaresPayload.count / this.paginationOptions.pageSize);
					}
				})
			).subscribe();

		this.subscriptions.listPrices = this.$services.prices.prices$
			.pipe(
				tap((priceGroupPayload: IListPricesPayload | null) => {
					if (priceGroupPayload) {
						priceGroupPayload.priceGroups.forEach((price) => {
							this.prices[price.name as string] = +price.price;
						});
					}
				})
			).subscribe();

		this.subscriptions.requestResult = this.$services.appAndFirmware.requestResult$
			.pipe(
				skipWhile((requestPayload) => requestPayload === null),
				tap((requestPayload: IRequestPayload | null) => {
					if (requestPayload) {
						if (requestPayload.actor === 'enableFirmware' || requestPayload.actor === 'disableFirmware') {
							this.listFirmwares();
						}
					}
				})
			).subscribe();
	}

	private unsubscribeFromStore(): void {
		Object.keys(this.subscriptions).map(key => this.subscriptions[key as keyof ISubscriptions])
			.forEach((subscription: Subscription | null) => {
				if (subscription) {
					subscription.unsubscribe();
				}
			});
	}

	private listFirmwares() {
		const dto: IListFirmwaresDto = {
			page : +this.paginationOptions.current,
			pageLength : this.paginationOptions.pageSize,
			showDisabled : (this.modelFirmwares.fields.showDisabled.value === true),
			search : `${this.filterModel.fields.search.value}`,
		};

		if (this.filterModel.fields.select.value === 'RC') {
			dto.showOnlyRc = true;
		} else if (this.filterModel.fields.select.value === 'RELEASE') {
			dto.showOnlyRelease = true;
		}
		this.$services.appAndFirmware.listFirmwares(dto);
	}

	public filterList(filter: string): void {
		this.$formValidation.onInput(this.filterModel, 'select', filter);
		this.listFirmwares();
	}

	public onPaginationPageChange(newPageNumber: number): void {
		this.paginationOptions.current = newPageNumber;
		this.listFirmwares();
	}
}
