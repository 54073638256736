






























import { Component, Prop, Vue } from 'vue-property-decorator';
import { ButtonStatusTypes, PanelHeaderStatusTypes } from '@/services/common/ui.service';

@Component({
	name : 'wb-reject-balance-request-modal',
	components : {
	},
})
export default class WbRejectBalanceRequestModal extends Vue {
	public panelHeaderStatusTypes = PanelHeaderStatusTypes;
	public buttonStatusTypes = ButtonStatusTypes;

	@Prop({ default : -1, })
	public balanceRequestId!: number;

	/**
	 * CREATED
	 */
	public created(): void {
		// eslint-disable-next-line no-console
		console.info('%c CREATE WbRejectBalanceRequestModal ', 'background: blue; color: #FFF');
	}

	public destroyed(): void {
		// eslint-disable-next-line no-console
		console.info('%c DESTROY WbRejectBalanceRequestModal ', 'background: purple; color: #FFF');
	}

	public reject(): void {
		this.$emit('confirm', this.balanceRequestId);
	}
}
