





























import { Component, Vue } from 'vue-property-decorator';

import { PanelHeaderStatusTypes } from '@/services/common/ui.service';
import WbDoughnutChart from '@/components/ui-elements/chart/wb-doughnut-chart/wb-doughnut-chart.vue';
import WbPieChart from '@/components/ui-elements/chart/wb-pie-chart/wb-pie-chart.vue';
import WbLineChart, {
	ILine,
	ILineChartData
} from '@/components/ui-elements/chart/wb-line-chart/wb-line-chart.vue';
import WbBarChart, { IBar } from '@/components/ui-elements/chart/wb-bar-chart/wb-bar-chart.vue';
import WbBarLineChart from '@/components/ui-elements/chart/wb-bar-line-chart/wb-bar-line-chart.vue';

@Component({
	name : 'wb-admin-home',
	components : {
		WbDoughnutChart,
		WbPieChart,
		WbLineChart,
		WbBarChart,
		WbBarLineChart,
	},
})
export default class WbAdminHome extends Vue {
	public panelHeaderStatusTypes = PanelHeaderStatusTypes;
	/**
	 * CREATED
	 */
	public created(): void {
		// eslint-disable-next-line no-console
		console.info('%c CREATE WbAdminHome ', 'background: blue; color: #FFF');
	}

	public destroyed(): void {
		// eslint-disable-next-line no-console
		console.info('%c DESTROY WbAdminHome ', 'background: purple; color: #FFF');
	}
}
