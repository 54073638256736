










import { Component, Prop, Vue } from 'vue-property-decorator';

import {
	Chart,
	ChartConfiguration,
	Color,
	LegendItem,
	registerables
} from 'chart.js';
import { LayoutPosition } from 'chart.js/types/layout';

Chart.register(...registerables);

export interface IPieChartDataSet {
	key: string;
	value: number;
}

export interface IPieChartSector {
	[sectors: string]: {
		label: string;
		borderColor?: Color | undefined;
		backgroundColor?: Color | undefined;
		hoverBorderColor?: Color | undefined;
		hoverBackgroundColor?: Color | undefined;
	};
}

export interface ILegend {
	legend: {
		options: {
			labels: {
				pointStyle: string;
			};
		};
	};
}

@Component({
	name : 'wb-pie-chart',
	components : {},
})

export default class WbPieChart extends Vue {
	private pieChartInstance: Chart<'pie'> | null = null;
	private canShowLegends = true;

	@Prop({ required : false, default : '', })
	private chartName!: string;

	@Prop({ required : false, default : 0, })
	private width!: number;

	@Prop({ required : false, default : 0, })
	private height!: number;

	@Prop({ required : false, default : true, })
	private legend!: boolean;

	@Prop({ required : false, default : true, })
	private legendToggle!: boolean;

	@Prop({ required : false, default : 'top', })
	private legendPosition!: LayoutPosition;

	@Prop({ required : false, default : 10, })
	private legendLabelsPadding!: number;

	@Prop({ required : false, default : 'full', })
	private discType!: 'full' | 'half';

	@Prop({ required : false, default : 0, })
	private padding!: number;

	@Prop({ required : true, default : null, })
	private data!: IPieChartDataSet[] | null;

	@Prop({ required : true, default : null, })
	private sectors!: IPieChartSector | null;

	private chartConfig: ChartConfiguration<'pie'> = {
		type : 'pie',
		data : {
			datasets : [{
				data : [],
				backgroundColor : [],
				hoverOffset : 10,
			}],
			labels : [],
		},
		options : {
			responsive : true,
			maintainAspectRatio : false,
			/* animation : {
				duration : 500,
				easing : 'easeOutQuart',
				onComplete : event => {
					const ctx = this.pieChartInstance.ctx;
					ctx?.fillText('example', 250, 150, )
				}
			} */
			plugins : {
				title : {
					display : false,
					text : '',
				},
				legend : {
					display : true,
					position : 'top',
					labels : {
						padding : 0,
						usePointStyle : true,
						pointStyle : 'circle',

						// eslint-disable-next-line @typescript-eslint/ban-ts-comment
						// @ts-ignore "TS error comes from the library, but it works well"
						generateLabels(chart: Chart & ILegend) {
							const data = chart.data;

							if (data.labels && data.labels.length && data.datasets.length) {
								const { labels : { pointStyle, }, } = chart.legend.options;

								return data.labels.map((label, i) => {
									const meta = chart.getDatasetMeta(0);
									const style = meta.controller.getStyle(i, true);

									return {
										text : `${label as string} - ${data.datasets[0].data[i] as unknown as string}`,
										fillStyle : style.backgroundColor,
										strokeStyle : style.borderColor,
										pointStyle : pointStyle,
										hidden : !chart.getDataVisibility(i),
										index : i,
									};
								});
							}
							return [];
						},
					},
				},
			},

			circumference : 360,
			rotation : 0,
			layout : {
				padding : 0,
			},
		},
	};

	private isInitialized = false;
	private chartCreationCounter = 0;

	public style = {
		width : '100%',
		height : '100%',
	}

	public dimensionError = false;

	/**
	 * CREATED
	 */

	public mounted(): void {
		// view-source:https://www.chartjs.org/docs/latest/samples/other-charts/pie.html
		this.initChart();

		// eslint-disable-next-line no-console
		console.info('%c CREATE WbPieChart', 'background: blue; color: #FFF');
	}

	public destroyed(): void {
		// eslint-disable-next-line no-console
		console.info('%c DESTROY WbPieChart', 'background: purple; color: #FFF');
	}

	private processConfigChartDimensions(): void {
		if (this.width !== undefined && this.width > 0) {
			this.style.width = `${this.width}px`;
		}
		if (this.height !== undefined && this.height > 0) {
			this.style.height = `${this.height}px`;
		}
	}

	private setChartIsInitialized(state: boolean) {
		this.isInitialized = state;
	}

	private getChartIsInitialized(): boolean {
		return this.isInitialized;
	}

	private processConfigChartTitle(): void {
		if (this.chartConfig && this.chartConfig.options && this.chartConfig.options.plugins) {
			const title = this.chartConfig.options.plugins.title;

			if (this.chartName !== undefined) {
				if (title) {
					title.display = true;
					title.text = '' + this.chartName;
				}
			}

			if (this.chartName === '') {
				if (title) {
					title.display = false;
				}
			}
		}
	}

	private processConfigChartData(): void {
		if (this.chartConfig && this.chartConfig.data) {
			const allowedKeys = this.sectors && Object.keys(this.sectors);
			this.chartConfig.data.labels = [];
			this.chartConfig.data.datasets[0].data = [];
			this.chartConfig.data.datasets[0].backgroundColor = [];
			this.chartConfig.data.datasets[0].hoverBackgroundColor = [];
			this.chartConfig.data.datasets[0].hoverBorderColor = [];
			if (this.data !== null) {
				this.data.forEach((data) => {
					if (allowedKeys && allowedKeys.indexOf(data.key) > -1) {
						this.chartConfig.data.datasets[0].data.push(data.value);
						if (this.sectors) {
							Object.keys(this.sectors).forEach((sector: keyof IPieChartSector) => {
								if (
									this.chartConfig && this.chartConfig.data && this.chartConfig.data.labels && this.chartConfig.data.datasets &&
									this.sectors &&
									data.key === sector
								) {
									this.chartConfig.data.labels.push(this.sectors[sector].label);
									(this.chartConfig.data.datasets[0].backgroundColor as Color[]).push(this.sectors[sector].backgroundColor as Color);
									(this.chartConfig.data.datasets[0].hoverBackgroundColor as Color[]).push(this.sectors[sector].backgroundColor as Color);
									(this.chartConfig.data.datasets[0].hoverBorderColor as Color[]).push(this.sectors[sector].hoverBorderColor as Color);
								}
							});
						}
					}
				});
			}
		}
	}

	private processConfigChartDiscType(): void {
		if (this.discType === 'half') {
			const options = this.chartConfig.options;
			if (options) {
				options.circumference = 180;
				options.rotation = 270;
			}
		}
	}

	private processConfigChartPadding(): void {
		if (this.discType && this.chartConfig && this.chartConfig.options) {
			const layout = this.chartConfig.options.layout;
			if (layout) {
				layout.padding = this.padding;
			}
		}
	}

	private processConfigChartLegend(): void {
		// Hide legend
		if (
			this.chartConfig && this.chartConfig.options && this.chartConfig.options.plugins &&
			this.legend !== undefined && !this.legend
		) {
			const legend = this.chartConfig.options.plugins.legend;
			if (legend) {
				legend.display = false;
				this.canShowLegends = false;
			}
		}

		// Set legend position
		if (
			this.chartConfig && this.chartConfig.options && this.chartConfig.options.plugins &&
			this.legendPosition && this.canShowLegends
		) {
			const legend = this.chartConfig.options.plugins.legend || {};
			if (legend.position) {
				legend.position = this.legendPosition;
			}
		}

		if (
			this.chartConfig && this.chartConfig.options && this.chartConfig.options.plugins &&
			this.legendToggle !== undefined && !this.legendToggle && this.canShowLegends
		) {
			const legend = this.chartConfig.options.plugins.legend;
			if (legend) {
				legend.onClick = () => {
					// do nothing
				};
			}
		} else if (
			this.chartConfig && this.chartConfig.options && this.chartConfig.options.plugins &&
			this.canShowLegends
		) {
			const legend = this.chartConfig.options.plugins.legend;

			if (legend) {
				legend.onClick = (e, legendItem: LegendItem & { index: number }, legendElement) => {
					const lci = legendElement.chart;
					const legendItems = legendElement.legendItems as LegendItem[];
					const index = legendItem.index;

					let counterVisibleElement = 0;
					legendItems.forEach((legendItem, index) => {
						if (lci.getDataVisibility(index)) {
							counterVisibleElement++;
						}
					});

					if (lci.getDataVisibility(index)) {
						if (counterVisibleElement > 1) {
							lci.toggleDataVisibility(index);
						}
					} else {
						lci.toggleDataVisibility(index);
					}

					lci.update();
				};
			}
		}
	}

	private processConfigChartLegendLabelsPadding(): void {
		// Set Legend Labels Padding
		if (
			this.chartConfig && this.chartConfig.options && this.chartConfig.options.plugins && this.chartConfig.options.plugins.legend &&
			this.legendLabelsPadding && this.canShowLegends
		) {
			const labels = this.chartConfig.options.plugins.legend.labels || {};
			if (labels) {
				labels.padding = this.legendLabelsPadding;
			}
		}
	}

	private createChartInstance() {
		if (this.$refs) {
			if (this.getChartIsInitialized() && this.pieChartInstance !== null) {
				this.pieChartInstance.destroy();
				this.setChartIsInitialized(false);
			}

			const ctx = this.$refs.canvas as HTMLCanvasElement;
			this.pieChartInstance = new Chart<'pie'>(ctx, this.chartConfig);
			this.setChartIsInitialized(true);
		}
	}

	private checkParentDimensionsIsOkayAndInit(): void {
		this.chartCreationCounter += 1;
		let parentHeight = 0;
		let parentWidth = 0;

		if (this.$refs && this.$refs.canvas) {
			const parentElement = (this.$refs.canvas as HTMLCanvasElement).parentElement;

			if (parentElement) {
				parentHeight = parentElement.getBoundingClientRect().height;
				parentWidth = parentElement.getBoundingClientRect().width;
			}

			if (parentHeight > 0 && parentWidth > 0) {
				this.createChartInstance();
			} else if (this.chartCreationCounter < 100) {
				setTimeout(() => {
					this.checkParentDimensionsIsOkayAndInit();
				}, 10);
			} else {
				this.style.width = '200px';
				this.style.height = '100px';
				this.dimensionError = true;
			}
		}
	}

	private initChart(): void {
		this.processConfigChartDimensions();
		this.processConfigChartDiscType();
		this.processConfigChartPadding();
		this.processConfigChartData();
		this.processConfigChartTitle();
		this.processConfigChartLegend();
		this.processConfigChartLegendLabelsPadding();

		this.chartCreationCounter = 0;
		this.checkParentDimensionsIsOkayAndInit();
	}
}

/**
 * How to use
 *
 */
