











































import { Subscription } from 'rxjs';
import { skipWhile, tap } from 'rxjs/operators';
import { Component, Prop, Vue } from 'vue-property-decorator';

import { IModel } from '@/services/common/form-validate.service';
import { ButtonStatusTypes, PanelHeaderStatusTypes } from '@/services/common/ui.service';

import { IListOnePriceGroupPayload, IPrice } from '@/services/api/price-group-api.service';
import { IRequestPayload } from '@/services/dtos/shared.dto';

interface ISubscriptions {
	listOnePriceGroup: Subscription | null;
	requestResult: Subscription | null;
}

@Component({
	name : 'wb-edit-user-modal',
	components : {
	},
})
export default class WbEditPriceModal extends Vue {
	@Prop({ default : {}, })
	public data!: IPrice;

	public panelHeaderStatusTypes = PanelHeaderStatusTypes;
	public buttonStatusTypes = ButtonStatusTypes;

	private subscriptions: ISubscriptions = {
		listOnePriceGroup : null,
		requestResult : null,
	};

	public model: IModel = {
		fields : {
			price : {
				oldValue : '',
				value : '',
				placeholder : 'Price',
				required : true,
				status : {},
				focus : false,
				error : {
					required : 'price is required.',
				},
			},
		},
		properties : {
			dirtyFields : [],
		},
		responseError : null,
	};

	public isBusy = false;

	/**
	 * CREATED
	 */
	public created(): void {
		this.resetStateAndSubscribeToStore();
		this.$services.prices.listOnePrice({ id : this.data.id, });

		// eslint-disable-next-line no-console
		console.info('%c CREATE WbEditPriceModal ', 'background: blue; color: #FFF');
	}

	public destroyed(): void {
		this.unsubscribeFromStore();

		// eslint-disable-next-line no-console
		console.info('%c DESTROY WbEditPriceModal ', 'background: purple; color: #FFF');
	}

	public edit(): void {
		if (this.model.properties.dirtyFields.length) {
			const validation = this.$formValidation.validate(this.model);

			if (validation.ok()) {
				validation.modifiedCurrResult.id = this.data.id;
				this.$services.prices.modifyPrice(validation.modifiedCurrResult);
				this.isBusy = true;
			}
		}
	}

	private resetStateAndSubscribeToStore(): void {
		this.$services.prices.resetRequestResult(); // !!! oder

		this.subscriptions.listOnePriceGroup = this.$services.prices.selectedPrice$
			.pipe(
				tap((selectedPricePayload: IListOnePriceGroupPayload | null) => {
					if (selectedPricePayload) {
						const fields = this.model.fields;
						fields.price.value = fields.price.oldValue = selectedPricePayload.price;
					}
				})
			).subscribe();

		this.subscriptions.requestResult = this.$services.prices.requestResult$
			.pipe(
				skipWhile((requestPayload) => requestPayload === null),
				tap((requestPayload: IRequestPayload | null) => {
					if (requestPayload) {
						if (requestPayload.actor === 'modifyPrice') {
							this.$emit('close-modal');
						}
					}

					this.isBusy = false;
				})
			).subscribe();
	}

	private unsubscribeFromStore() {
		Object.keys(this.subscriptions).map(key => this.subscriptions[key as keyof ISubscriptions])
			.forEach((subscription: Subscription | null) => {
				if (subscription) {
					subscription.unsubscribe();
				}
			});
	}
}
