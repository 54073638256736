




































































import { Component, Vue } from 'vue-property-decorator';

import { skipWhile, tap } from 'rxjs/operators';
import { Subscription } from 'rxjs';

import { PanelHeaderStatusTypes } from '@/services/common/ui.service';

import { IListPricesPayload, IPrice } from '@/services/api/price-group-api.service';
import { IListDto/* , IRequestPayload */ } from '@/services/dtos/shared.dto';
import { IModel } from '@/services/common/form-validate.service';
import WbEditPriceModal
	from '@/views/wb-app/wb-site/admin/wb-admin-apps-and-firmwares/wb-admin-prices/wb-edit-price-modal/wb-edit-price-modal.vue';

interface ISubscriptions {
	listPrices: Subscription | null;
	requestResult: Subscription | null;
}

@Component({
	name : 'wb-admin-apps-and-firmwares',
	components : {
		WbEditPriceModal,
	},
})
export default class WbAdminPrices extends Vue {
	public panelHeaderStatusTypes = PanelHeaderStatusTypes;

	public prices: {[key: string]: number} = {};

	public priceGroups: IPrice[] = [];

	public modal = {
		editPrice : false,
	}

	public filterModel: IModel = {
		fields : {
			search : {
				placeholder : 'Search name',
				oldValue : '',
				value : '',
				required : false,
				focus : false,
				status : {},
				error : {},
			},
		},
		properties : {
			dirtyFields : [],
		},
	};

	public selectedPrice: IPrice = {
		id : -1,
		name : '',
		price : -1,
	};

	private subscriptions: ISubscriptions = {
		listPrices : null,
		requestResult : null,
	}

	public paginationOptions = {
		min : 1,
		current : 1,
		max : 1,
		pageSize : 25,
	};

	/**
	 * CREATED
	 */
	public created(): void {
		this.subscribeToStore();
		this.listPrices();

		// eslint-disable-next-line no-console
		console.info('%c CREATE WbAdminAppsAndFirmwares.WbAdminPrices ', 'background: blue; color: #FFF');
	}

	public destroyed(): void {
		this.unsubscribeFromStore();

		// eslint-disable-next-line no-console
		console.info('%c DESTROY WbAdminAppsAndFirmwares.WbAdminPrices ', 'background: purple; color: #FFF');
	}

	public openEditPriceModal(priceGroup: IPrice): void {
		this.selectedPrice = priceGroup;
		this.modal.editPrice = true;
	}

	public closeEditPriceModal(): void {
		this.modal.editPrice = false;
	}

	private subscribeToStore(): void {
		this.subscriptions.listPrices = this.$services.prices.prices$
			.pipe(
				tap((priceGroupPayload: IListPricesPayload | null) => {
					if (priceGroupPayload) {
						this.priceGroups = priceGroupPayload.priceGroups;
						priceGroupPayload.priceGroups.forEach((price) => {
							this.prices[price.name as string] = +price.price;
						});
						this.paginationOptions.max = Math.ceil(priceGroupPayload.count / this.paginationOptions.pageSize);

						this.listPrices();
					}
				})
			).subscribe();

		this.subscriptions.requestResult = this.$services.appAndFirmware.requestResult$
			.pipe(
				skipWhile((requestPayload) => requestPayload === null),
				tap((/* requestPayload: IRequestPayload  | null */) => {
					// if (requestPayload) {}
				})
			).subscribe();
	}

	private unsubscribeFromStore(): void {
		Object.keys(this.subscriptions).map(key => this.subscriptions[key as keyof ISubscriptions])
			.forEach((subscription: Subscription | null) => {
				if (subscription) {
					subscription.unsubscribe();
				}
			});
	}

	public listPrices(): void {
		const dto: IListDto = {
			page : this.paginationOptions.current,
			pageLength : this.paginationOptions.pageSize,
			search : this.filterModel.fields.search.value.toString(),
		};
		this.$services.prices.listPrices(dto);
	}

	public onPaginationPageChange(newPageNumber: number): void {
		this.paginationOptions.current = newPageNumber;
		this.listPrices();
	}
}
