















/**
 * File Downloader
 *
 * @copyright Wallboard Inc. 2021
 * @version V1.0.0
 * @author  Gabor Fabian, Tamas Dreissiger
 * @utf-8   ő
 */
import { Component, Vue, Prop } from 'vue-property-decorator';
import WbProgressBar from '@/components/ui-elements/wb-progress-bar/wb-progress-bar.vue';

@Component({
	name : 'wb-file-downloader',
	components : {
		WbProgressBar,
	},
})
export default class WbFileDownloader extends Vue {
	@Prop({ default : '', })
	public link!: string;

	@Prop({ default : '', })
	public fileName!: string;

	public downloading = false;

	public percent = 0;
	public error = false;
	public errorMessage = 'Error happened';

	/**
	 * CREATED
	 */
	public created(): void {
		// eslint-disable-next-line no-console
		console.info('%c INIT uiElements.wbFileDownloader ', 'background: blue; color: #FFF');
	}

	public destroyed(): void {
		// eslint-disable-next-line no-console
		console.info('%c DESTROY uiElements.wbFileDownloader ', 'background: purple; color: #FFF');
	}

	public downloadApp(): void {
		if (this.link !== '' && this.fileName !== '') {
			this.downloading = true;

			// eslint-disable-next-line @typescript-eslint/no-this-alias
			const self = this;

			this.$http.download('downloadApp', this.link, this.fileName).progress$
				.subscribe({
					next(percent: number) {
						self.percent = percent;
					},
					complete() {
						self.downloading = false;
					},
					error(err: string) {
						self.errorMessage = err;
						self.error = true;
						self.downloading = false;
					},
				});
		}
	}
}
