




















































import { Subscription } from 'rxjs';
import { skipWhile, tap } from 'rxjs/operators';
import { Component, Prop, Vue } from 'vue-property-decorator';

import { ButtonStatusTypes, PanelHeaderStatusTypes } from '@/services/common/ui.service';
import { IModel } from '@/services/common/form-validate.service';

import { IApp } from '@/services/app-and-firmware-store.service';
import { IListOneAppPayload } from '@/services/api/app-and-firmware-api.service';
import { IRequestPayload } from '@/services/dtos/shared.dto';

interface ISubscriptions {
	listOneApp: Subscription | null;
	editApp: Subscription | null;
}

@Component({
	name : 'wb-edit-app-modal',
	components : {
	},
})
export default class WbEditAppModal extends Vue {
	@Prop({ default : {}, })
	public data!: IApp;

	public panelHeaderStatusTypes = PanelHeaderStatusTypes;
	public buttonStatusTypes = ButtonStatusTypes;

	private subscriptions: ISubscriptions = {
		listOneApp : null,
		editApp : null,
	};

	public model: IModel = {
		fields : {
			description : {
				oldValue : '',
				value : '',
				placeholder : 'Description',
				required : false,
				status : {},
				focus : false,
				error : {
					required : 'Description is required.',
				},
			},
			changeLog : {
				oldValue : '',
				value : '',
				placeholder : 'Changelog',
				required : false,
				status : {},
				focus : false,
				error : {
					required : 'Changelog is required.',
				},
			},
		},
		properties : {
			dirtyFields : [],
		},
		responseError : null,
	};

	public isBusy = false;

	/**
	 * CREATED
	 */
	public created(): void {
		this.resetStateAndSubscribeToStore();
		this.$services.appAndFirmware.listOneApp({ id : this.data.id, });
		// eslint-disable-next-line no-console
		console.info('%c CREATE WbEditAppModal ', 'background: blue; color: #FFF');
	}

	public destroyed(): void {
		this.unsubscribeFromStore();

		// eslint-disable-next-line no-console
		console.info('%c DESTROY WbEditAppModal ', 'background: purple; color: #FFF');
	}

	public edit(): void {
		if (this.model.properties.dirtyFields.length) {
			const validation = this.$formValidation.validate(this.model);

			if (validation.ok()) {
				validation.modifiedCurrResult.id = this.data.id;
				this.$services.appAndFirmware.modifyApp(validation.modifiedCurrResult);
				this.isBusy = true;
			}
		}
	}

	private resetStateAndSubscribeToStore(): void {
		this.$services.appAndFirmware.resetRequestResult(); // !!! oder

		this.subscriptions.listOneApp = this.$services.appAndFirmware.selectedApp$
			.pipe(
				tap((selectedAppPayload: IListOneAppPayload | null) => {
					if (selectedAppPayload) {
						const fields = this.model.fields;
						fields.description.value = fields.description.oldValue = selectedAppPayload.description;
						fields.changeLog.value = fields.changeLog.oldValue = selectedAppPayload.changeLog;
					}
				})
			).subscribe();

		this.subscriptions.editApp = this.$services.appAndFirmware.requestResult$
			.pipe(
				skipWhile((requestPayload) => requestPayload === null),
				tap((requestPayload: IRequestPayload | null) => {
					if (requestPayload) {
						if (requestPayload.actor === 'modifyApp') {
							this.$emit('close-modal');
						}
					}

					this.isBusy = false;
				})
			).subscribe();
	}

	private unsubscribeFromStore(): void {
		Object.keys(this.subscriptions).map(key => this.subscriptions[key as keyof ISubscriptions])
			.forEach((subscription: Subscription | null) => {
				if (subscription) {
					subscription.unsubscribe();
				}
			});
	}
}
