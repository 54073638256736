






















































































































import { Component, Vue } from 'vue-property-decorator';

import { Subscription } from 'rxjs';
import { skipWhile, tap } from 'rxjs/operators';

import { IModel, InputTypeTypes } from '@/services/common/form-validate.service';
import { ButtonStatusTypes, PanelHeaderStatusTypes } from '@/services/common/ui.service';

import { UserRoles } from '@/services/payloads/user.payload';
import { IRequestPayload } from '@/services/dtos/shared.dto';

interface ISubscriptions {
	addUser: Subscription | null;
}

@Component({
	name : 'wb-add-user-modal',
	components : {
	},
})
export default class WbAddUserModal extends Vue {
	public userRoles = UserRoles;

	public panelHeaderStatusTypes = PanelHeaderStatusTypes;
	public buttonStatusTypes = ButtonStatusTypes;

	private subscriptions: ISubscriptions = {
		addUser : null,
	}

	public model: IModel = {
		fields : {
			organization : {
				oldValue : '',
				value : '',
				placeholder : 'Company Name',
				required : true,
				status : {},
				focus : false,
				error : {
					required : 'Company name is required.',
				},
			},
			email : {
				oldValue : '',
				value : '',
				type : InputTypeTypes.email,
				placeholder : 'Email address',
				required : true,
				status : {},
				focus : false,
				error : {
					required : 'Email address is required.',
					email : 'This is not an email address',
				},
			},
			firstname : {
				oldValue : '',
				value : '',
				placeholder : 'First name',
				required : true,
				status : {},
				focus : false,
				error : {
					required : 'First name is required.',
				},
			},
			lastname : {
				oldValue : '',
				value : '',
				placeholder : 'Last name',
				required : true,
				status : {},
				focus : false,
				error : {
					required : 'Last name is required.',
				},
			},
			role : {
				oldValue : '',
				value : '',
				required : true,
				status : {},
				focus : false,
				error : {
					required : 'Select a role',
				},
				options : [
					{ value : '', label : '-- Select a role --', },
					{ value : UserRoles.USER, label : 'User', },
					{ value : UserRoles.ADMIN, label : 'Admin', },
					{ value : UserRoles.SUPERADMIN, label : 'Super Admin', }
				],
			},
			isDeveloper : {
				oldValue : false,
				value : false,
				placeholder : 'Developer',
				required : true,
				status : {},
				focus : false,
				error : {
					required : 'Required.',
				},
			},
			isIsaac : {
				oldValue : false,
				value : false,
				placeholder : 'Isaac',
				required : true,
				status : {},
				focus : false,
				error : {
					required : 'Required.',
				},
			},
			canModifyBalance : {
				oldValue : false,
				value : false,
				placeholder : 'Can modify balance',
				required : true,
				status : {},
				focus : false,
				error : {
					required : 'Required.',
				},
			},
			topUpRequestRecipient : {
				oldValue : false,
				value : false,
				placeholder : 'Can receive email about request of top up',
				required : true,
				status : {},
				focus : false,
				error : {
					required : 'Required.',
				},
			},
			userRegisteredRecipient : {
				oldValue : false,
				value : false,
				placeholder : 'Can receive email about registration',
				required : true,
				status : {},
				focus : false,
				error : {
					required : 'Required.',
				},
			},
		},
		properties : {
			dirtyFields : [],
		},
		responseError : null,
	};

	public isBusy = false;

	/**
	 * CREATED
	 */
	public created(): void {
		// eslint-disable-next-line no-console
		console.info('%c CREATE WbAddUserModal ', 'background: blue; color: #FFF');

		if (this.$auth.getUserRole() === UserRoles.ADMIN) {
			this.model.fields.role.value = UserRoles.USER;
			this.model.fields.role.oldValue = UserRoles.USER;
		}

		this.subscribeToStore();
	}

	public destroyed(): void {
		// eslint-disable-next-line no-console
		console.info('%c DESTROY WbAddUserModal ', 'background: purple; color: #FFF');

		this.unsubscribeFromStore();
	}

	public add(): void {
		const validation = this.$formValidation.validate(this.model);

		if (validation.ok()) {
			// Register
			this.$services.user.addUser(validation.result);
			this.isBusy = true;
		}
	}

	private subscribeToStore(): void {
		this.subscriptions.addUser = this.$services.user.requestResult$
			.pipe(
				skipWhile((requestPayload) => requestPayload === null),
				tap((requestPayload: IRequestPayload | null) => {
					if (requestPayload) {
						if (requestPayload.actor === 'addUser') {
							this.$emit('close-modal');
						}
					} else {
						/* this.registrationModel.responseError = {
							status : StatusTypes.danger,
							message : 'Error happened, please try again',
						}; */
					}

					this.isBusy = false;
				})
			).subscribe();
	}

	private unsubscribeFromStore(): void {
		Object.keys(this.subscriptions).map(key => this.subscriptions[key as keyof ISubscriptions])
			.forEach((subscription: Subscription | null) => {
				if (subscription) {
					subscription.unsubscribe();
				}
			});
	}
}
