














































































































































import { Component, Vue } from 'vue-property-decorator';

import { Subscription } from 'rxjs';
import { skipWhile, tap } from 'rxjs/operators';

import { ButtonStatusTypes, PanelHeaderStatusTypes } from '@/services/common/ui.service';
import { IModel } from '@/services/common/form-validate.service';

import { IAndroidFirmware } from '@/services/app-and-firmware-store.service';
import { IListAndroidFirmwaresPayload } from '@/services/api/android-firmware-api.service';
import { IRequestPayload } from '@/services/dtos/shared.dto';

import WbUploadAndroidFirmwareModal from './modals/wb-upload-android-firmware-modal/wb-upload-android-firmware-modal.vue';
import WbDisableAndroidFirmwareModal from './modals/wb-disable-android-firmware-modal/wb-disable-android-firmware-modal.vue';
import WbEnableAndroidFirmwareModal from './modals/wb-enable-android-firmware-modal/wb-enable-android-firmware-modal.vue';
import WbEditAndroidFirmwareModal from './modals/wb-edit-android-firmware-modal/wb-edit-android-firmware-modal.vue';
import WbDeleteAndroidFirmwareModal
	from '@/views/wb-app/wb-site/admin/wb-admin-apps-and-firmwares/wb-admin-android-firmwares/modals/wb-delete-android-firmware-modal/wb-delete-android-firmware-modal.vue';

interface ISubscriptions {
	listAndroidFirmwares: Subscription | null;
	requestResult: Subscription | null;
}

@Component({
	name : 'wb-admin-firmwares',
	components : {
		WbDeleteAndroidFirmwareModal,
		WbUploadAndroidFirmwareModal,
		WbDisableAndroidFirmwareModal,
		WbEnableAndroidFirmwareModal,
		WbEditAndroidFirmwareModal,
	},
})
export default class WbAdminAndroidFirmwares extends Vue {
	public panelHeaderStatusTypes = PanelHeaderStatusTypes;
	public androidFirmwares: IAndroidFirmware[] = [];
	public buttonStatusTypes = ButtonStatusTypes;

	public modelFirmwares: IModel = {
		fields : {
			showDisabled : {
				oldValue : false,
				value : false,
				placeholder : 'ShowDisabled',
				required : true,
				status : {},
				focus : false,
				error : {
					required : 'ShowDisabled is required.',
				},
			},
		},
		properties : {
			dirtyFields : [],
		},
		responseError : null,
	};

	public modal = {
		upload : false,
		disableAndroidFirmware : false,
		enableAndroidFirmware : false,
		editAndroidFirmware : false,
		deleteAndroidFirmware : false,
	};

	public filterModel: IModel = {
		fields : {
			search : {
				placeholder : 'Search name',
				oldValue : '',
				value : '',
				required : false,
				focus : false,
				status : {},
				error : {},
			},
		},
		properties : {
			dirtyFields : [],
		},
	};

	public selectedAndroidFirmware: IAndroidFirmware | null = null;

	private subscriptions: ISubscriptions = {
		listAndroidFirmwares : null,
		requestResult : null,
	};

	public paginationOptions = {
		min : 1,
		current : 1,
		max : 1,
		pageSize : 25,
	};

	/**
	 * CREATED
	 */
	public created(): void {
		this.subscribeToStore();
		this.listAndroidFirmwares();

		// eslint-disable-next-line no-console
		console.info('%c CREATE WbAdminAppsAndFirmwares.WbAdminAndroidFirmwares ', 'background: blue; color: #FFF');
	}

	public destroyed(): void {
		this.unsubscribeFromStore();

		// eslint-disable-next-line no-console
		console.info('%c DESTROY WbAdminAppsAndFirmwares.WbAdminAndroidFirmwares ', 'background: purple; color: #FFF');
	}

	public openUploadAndroidFirmwareModal(): void {
		this.modal.upload = true;
	}

	public closeUploadAndroidFirmwareModal(): void {
		this.modal.upload = false;
		this.listAndroidFirmwares();
	}

	public openModalEditAndroidFirmware(androidFirmware: IAndroidFirmware): void {
		this.modal.editAndroidFirmware = true;
		this.selectedAndroidFirmware = androidFirmware;
	}

	public closeModalEditAndroidFirmware(): void {
		this.modal.editAndroidFirmware = false;
		this.listAndroidFirmwares();
	}

	public openEnableFirmwareModal(androidFirmware: IAndroidFirmware): void {
		this.selectedAndroidFirmware = androidFirmware;
		this.modal.enableAndroidFirmware = true;
	}

	public openDisableFirmwareModal(androidFirmware: IAndroidFirmware): void {
		this.selectedAndroidFirmware = androidFirmware;
		this.modal.disableAndroidFirmware = true;
	}

	public openDeleteFirmwareModal(androidFirmware: IAndroidFirmware): void {
		this.selectedAndroidFirmware = androidFirmware;
		this.modal.deleteAndroidFirmware = true;
	}

	public enableAndroidFirmware(androidFirmware: IAndroidFirmware): void {
		this.$services.appAndFirmware.enableAndroidFirmware(androidFirmware);
		this.modal.enableAndroidFirmware = false;
	}

	public disableAndroidFirmware(androidFirmware: IAndroidFirmware): void {
		this.$services.appAndFirmware.disableAndroidFirmware(androidFirmware);
		this.modal.disableAndroidFirmware = false;
	}

	public deleteAndroidFirmware(androidFirmware: IAndroidFirmware): void {
		this.$services.appAndFirmware.deleteAndroidFirmware(androidFirmware);
		this.modal.deleteAndroidFirmware = false;
	}

	public showDisabledAndroidFirmwares(newValue: boolean): void {
		this.$formValidation.onInput(this.modelFirmwares, 'showDisabled', newValue);
		this.listAndroidFirmwares();
	}

	private subscribeToStore(): void {
		this.subscriptions.listAndroidFirmwares = this.$services.appAndFirmware.androidFirmwares$
			.pipe(
				tap((androidFirmwaresPayload: IListAndroidFirmwaresPayload | null) => {
					if (androidFirmwaresPayload) {
						this.androidFirmwares = androidFirmwaresPayload.androidFirmwares.sort((a: IAndroidFirmware, b: IAndroidFirmware) => {
							return +b.createdAt - +a.createdAt;
						});
						this.paginationOptions.max = Math.ceil(androidFirmwaresPayload.count / this.paginationOptions.pageSize);
					}
				})
			).subscribe();

		this.subscriptions.requestResult = this.$services.appAndFirmware.requestResult$
			.pipe(
				skipWhile((requestPayload) => requestPayload === null),
				tap((requestPayload: IRequestPayload | null) => {
					if (requestPayload) {
						if (requestPayload.actor === 'enableAndroidFirmware' ||
							requestPayload.actor === 'disableAndroidFirmware' ||
							requestPayload.actor === 'deleteAndroidFirmware') {
							this.listAndroidFirmwares();
						}
					}
				})
			).subscribe();
	}

	private unsubscribeFromStore(): void {
		Object.keys(this.subscriptions).map(key => this.subscriptions[key as keyof ISubscriptions])
			.forEach((subscription: Subscription | null) => {
				if (subscription) {
					subscription.unsubscribe();
				}
			});
	}

	private listAndroidFirmwares(): void {
		this.$services.appAndFirmware.listAndroidFirmwares({
			page : this.paginationOptions.current,
			pageLength : this.paginationOptions.pageSize,
			showDisabled : (this.modelFirmwares.fields.showDisabled.value === true),
			search : this.filterModel.fields.search.value.toString(),
		});
	}

	public onPaginationPageChange(newPageNumber: number): void {
		this.paginationOptions.current = newPageNumber;
		this.listAndroidFirmwares();
	}
}
