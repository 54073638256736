































import { Component, Prop, Vue } from 'vue-property-decorator';
import { ButtonStatusTypes, PanelHeaderStatusTypes } from '@/services/common/ui.service';
import { IAndroidFirmware } from '@/services/app-and-firmware-store.service';

@Component({
	name : 'wb-disable-android-firmware-modal',
	components : {
	},
})
export default class WbDisableAndroidFirmwareModal extends Vue {
	public panelHeaderStatusTypes = PanelHeaderStatusTypes;
	public buttonStatusTypes = ButtonStatusTypes;

	@Prop({ default : -1, })
	public androidFirmwareId!: IAndroidFirmware;

	/**
	 * CREATED
	 */
	public created(): void {
		// eslint-disable-next-line no-console
		console.info('%c CREATE WbDisableAndroidFirmwareModal ', 'background: blue; color: #FFF');
	}

	public destroyed(): void {
		// eslint-disable-next-line no-console
		console.info('%c DESTROY WbDisableAndroidFirmwareModal ', 'background: purple; color: #FFF');
	}

	public disable(): void {
		this.$emit('confirm', this.androidFirmwareId);
	}
}
