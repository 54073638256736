


















































import { Component, Prop, Vue } from 'vue-property-decorator';
import { Observable, Subscription } from 'rxjs';
import { tap } from 'rxjs/operators';

import { UserLogTypes } from '@/services/payloads/user.payload';
import {
	IListUserPaymentHistoryDto,
	IListUserPaymentHistoryPayload,
	IPaymentHistory
} from '@/services/api/balance-api.service';

interface IFilteredPaymentHistory {
event: string;
amount: number;
message: string;
message2: string;
time: string;
color: string;
}

interface ISubscriptions {
	paymentHistories: Subscription | null;
	restart: Subscription | null;
}

@Component({
	name : 'wb-payment-history',
	components : {
	},
})

export default class WbPaymentHistory extends Vue {
	@Prop({ default : -1, })
	public id!: number;

	@Prop({ default : 'own', })
	public type!: 'own' | 'user';

	@Prop({ default : null, })
	public restart$!: Observable<boolean>;

	private subscriptions: ISubscriptions = {
		paymentHistories : null,
		restart : null,
	};

	private isBusy = false;

	public paginationOptions = {
		min : 1,
		current : 1,
		max : 1,
		pageSize : 10,
	};

	public paymentHistories: IPaymentHistory[] = [];

	get filteredPaymentHistories(): IFilteredPaymentHistory[] {
		const log: IFilteredPaymentHistory[] = [];
		let event: string;
		let message: string;
		let message2: string;
		let time: string;
		let oldBalance: number;
		let newBalance: number;
		let color: string;

		this.paymentHistories.forEach((paymentHistory) => {
			event = '';
			message = '';
			message2 = '';
			time = this.$region.formatTime(paymentHistory.createdAt);
			oldBalance = 0;
			newBalance = 0;
			color = '';

			if (paymentHistory.type === UserLogTypes.balanceChanged) {
				if (paymentHistory.details && paymentHistory.details.firmwareName) {
					event = 'Firmware burnt';
					color = 'danger';
				} else if (paymentHistory.amount > 0) {
					event = 'Top up';
					color = 'success';
				} else {
					event = 'Withdraw';
					color = 'danger';
				}

				oldBalance = (paymentHistory.details && paymentHistory.details.oldBalance) ? +paymentHistory.details.oldBalance : -1;
				newBalance = (paymentHistory.details && paymentHistory.details.newBalance) ? +paymentHistory.details.newBalance : -1;

				if (oldBalance > 0 && newBalance > 0) {
					message = `
			Your balance has changed from ${oldBalance} to ${newBalance}
		`;
				} else {
					message = 'Unknown balance';
				}

				if (paymentHistory.details && paymentHistory.details.firmwareName) {
					message2 = `Firmware was burnt ${paymentHistory.details.firmwareName} ${paymentHistory.details.firmwareVersion}`;
				}

				log.push({ event, amount : paymentHistory.amount, message, message2, time, color, });
			} else if (paymentHistory.type === UserLogTypes.balanceTopUpRequested) {
				event = 'Request Credit';
				message = 'Request credit';
				color = 'info';
				log.push({ event, amount : paymentHistory.amount, message, message2, time, color, });
			} else if (paymentHistory.type === UserLogTypes.balanceRequestGranted) {
				event = 'Granted';
				message = 'Balance request status changed to GRANTED';
				color = 'success';
				log.push({ event, amount : paymentHistory.amount, message, message2, time, color, });
			} else if (paymentHistory.type === UserLogTypes.balanceRequestRejected) {
				event = 'Rejected';
				message = 'Balance request status changed to REJECTED';
				color = 'danger';
				log.push({ event, amount : paymentHistory.amount, message, message2, time, color, });
			}
		});

		return log;
	}

	/**
	* CREATED
	*/
	public created(): void {
		this.resetStateAndSubscribeToStore();
		this.listPaymentHistories();

		// eslint-disable-next-line no-console
		console.info('%c CREATE WbPaymentHistory ', 'background: blue; color: #FFF');
	}

	public destroyed(): void {
		this.unsubscribeFromStore();
		// eslint-disable-next-line no-console
		console.info('%c DESTROY WbPaymentHistory ', 'background: purple; color: #FFF');
	}

	public listPaymentHistories(): void {
		const options: IListUserPaymentHistoryDto = {
			page : this.paginationOptions.current,
			pageLength : this.paginationOptions.pageSize,
			search : '',
		};

		if (this.type === 'user') {
			options.id = this.id;
		}
		this.$services.balance.listOneUserPaymentHistory(options);
	}

	public onPaginationPageChange(newPageNumber: number): void {
		this.paginationOptions.current = newPageNumber;
		this.listPaymentHistories();
	}

	private resetStateAndSubscribeToStore(): void {
		this.$services.balance.resetPaymentHistory();
		this.subscriptions.paymentHistories = this.$services.balance.paymentHistories$
			.pipe(
				tap((paymentHistoriesPayload: IListUserPaymentHistoryPayload | null) => {
					if (paymentHistoriesPayload) {
						this.paymentHistories = paymentHistoriesPayload.userLogs;
						this.paginationOptions.max = Math.ceil(paymentHistoriesPayload.count / this.paginationOptions.pageSize);
						this.isBusy = true;
					}
				})
			).subscribe();

		if (this.restart$) {
			this.subscriptions.restart = this.restart$
				.pipe(
					tap((state: boolean) => {
						if (state) {
							this.listPaymentHistories();
						}
					})
				)
				.subscribe();
		}
	}

	private unsubscribeFromStore(): void {
		Object.keys(this.subscriptions).map(key => this.subscriptions[key as keyof ISubscriptions])
			.forEach((subscription: Subscription | null) => {
				if (subscription) {
					subscription.unsubscribe();
				}
			});
	}
}
