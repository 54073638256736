











































































import { Component, Prop, Vue } from 'vue-property-decorator';

import { Subscription } from 'rxjs';
import { skipWhile, tap } from 'rxjs/operators';

import { IModel } from '@/services/common/form-validate.service';
import { ButtonStatusTypes, PanelHeaderStatusTypes } from '@/services/common/ui.service';

import {
	IUser,
	IListOneUserPayload,
	UserRoles
} from '@/services/payloads/user.payload';
import { IRequestPayload } from '@/services/dtos/shared.dto';

interface ISubscriptions {
	listOneUser: Subscription | null;
	modifyUserPrivileges: Subscription | null;
}

@Component({
	name : 'wb-edit-user-modal-privileges',
	components : {
	},
})
export default class WbEditUserModalPrivileges extends Vue {
	public userRoles = UserRoles;

	@Prop({ default : {}, })
	public data!: IUser;

	public panelHeaderStatusTypes = PanelHeaderStatusTypes;
	public buttonStatusTypes = ButtonStatusTypes;

	private subscriptions: ISubscriptions = {
		listOneUser : null,
		modifyUserPrivileges : null,
	};

	public model: IModel = {
		fields : {
			developer : {
				oldValue : false,
				value : false,
				placeholder : 'Developer',
				required : true,
				status : {},
				focus : false,
				error : {
					required : 'Required.',
				},
			},
			isaac : {
				oldValue : false,
				value : false,
				placeholder : 'Isaac',
				required : true,
				status : {},
				focus : false,
				error : {
					required : 'Required.',
				},
			},
			canModifyBalance : {
				oldValue : false,
				value : false,
				placeholder : 'Can modify balance',
				required : true,
				status : {},
				focus : false,
				error : {
					required : 'Required.',
				},
			},
			topUpRequestRecipient : {
				oldValue : false,
				value : false,
				placeholder : 'Can receive email about request of top up',
				required : true,
				status : {},
				focus : false,
				error : {
					required : 'Required.',
				},
			},
			userRegisteredRecipient : {
				oldValue : false,
				value : false,
				placeholder : 'Can receive email about registration',
				required : true,
				status : {},
				focus : false,
				error : {
					required : 'Required.',
				},
			},
		},
		properties : {
			dirtyFields : [],
		},
		responseError : null,
	};

	public isBusy = false;

	/**
	 * CREATED
	 */
	public created(): void {
		this.resetStateAndSubscribeToStore();
		this.$services.user.listOneUser({ id : this.data.id, });

		// eslint-disable-next-line no-console
		console.info('%c CREATE WbEditUserPrivilegesModal ', 'background: blue; color: #FFF');
	}

	public destroyed(): void {
		this.unsubscribeFromStore();

		this.$services.user.resetRequestResult(); // !!! oder

		// eslint-disable-next-line no-console
		console.info('%c DESTROY WbEditUserPrivilegesModal ', 'background: purple; color: #FFF');
	}

	public edit(): void {
		if (this.model.properties.dirtyFields.length) {
			const validation = this.$formValidation.validate(this.model);

			if (validation.ok()) {
				validation.modifiedCurrResult.id = this.data.id;
				this.$services.user.modifyUserPrivileges(validation.modifiedCurrResult);
				this.isBusy = true;
			}
		}
	}

	private resetStateAndSubscribeToStore(): void {
		this.$services.user.resetRequestResult(); // !!! oder
		this.$services.user.resetSelectedUser();

		this.subscriptions.listOneUser = this.$services.user.selectedUser$
			.pipe(
				tap((selectedUserPayload: IListOneUserPayload | null) => {
					if (selectedUserPayload) {
						const fields = this.model.fields;
						fields.topUpRequestRecipient.value = fields.topUpRequestRecipient.oldValue = (selectedUserPayload.privilege.topUpRequestRecipient === true);
						fields.userRegisteredRecipient.value = fields.userRegisteredRecipient.oldValue = (selectedUserPayload.privilege.userRegisteredRecipient === true);
						fields.canModifyBalance.value = fields.canModifyBalance.oldValue = (selectedUserPayload.privilege.canModifyBalance === true);
						fields.developer.value = fields.developer.oldValue = (selectedUserPayload.privilege.developer === true);
						fields.isaac.value = fields.isaac.oldValue = (selectedUserPayload.privilege.isaac === true);

						if (selectedUserPayload.role) {
							this.data.role = selectedUserPayload.role;
						}
					}
				})
			).subscribe();

		this.subscriptions.modifyUserPrivileges = this.$services.user.requestResult$
			.pipe(
				skipWhile((requestPayload) => requestPayload === null),
				tap((requestPayload: IRequestPayload | null) => {
					if (requestPayload) {
						if (requestPayload.actor === 'modifyUserPrivileges') {
							this.$emit('close-modal');
						}
					}

					this.isBusy = false;
				})
			).subscribe();
	}

	private unsubscribeFromStore(): void {
		Object.keys(this.subscriptions).map(key => this.subscriptions[key as keyof ISubscriptions])
			.forEach((subscription: Subscription | null) => {
				if (subscription) {
					subscription.unsubscribe();
				}
			});
	}
}
