




















































import { Component, Prop, Vue } from 'vue-property-decorator';
import { Subscription } from 'rxjs';
import { skipWhile, tap } from 'rxjs/operators';

import { ButtonStatusTypes, PanelHeaderStatusTypes } from '@/services/common/ui.service';
import { IModel } from '@/services/common/form-validate.service';

import { IFirmware } from '@/services/app-and-firmware-store.service';

import { IListOneFirmwarePayload } from '@/services/api/app-and-firmware-api.service';
import { IRequestPayload } from '@/services/dtos/shared.dto';

interface ISubscriptions {
	listOneFirmware: Subscription | null;
	editFirmware: Subscription | null;
}

@Component({
	name : 'wb-edit-firmware-modal',
	components : {
	},
})
export default class WbEditFirmwareModal extends Vue {
	@Prop({ default : {}, })
	public data!: IFirmware;

	public panelHeaderStatusTypes = PanelHeaderStatusTypes;
	public buttonStatusTypes = ButtonStatusTypes;

	private subscriptions: ISubscriptions = {
		listOneFirmware : null,
		editFirmware : null,
	};

	public model: IModel = {
		fields : {
			description : {
				oldValue : '',
				value : '',
				placeholder : 'Description',
				required : false,
				status : {},
				focus : false,
				error : {
					required : 'Description is required.',
				},
			},
			changeLog : {
				oldValue : '',
				value : '',
				placeholder : 'Changelog',
				required : false,
				status : {},
				focus : false,
				error : {
					required : 'Changelog is required.',
				},
			},
		},
		properties : {
			dirtyFields : [],
		},
		responseError : null,
	};

	public isBusy = false;

	/**
	 * CREATED
	 */
	public created(): void {
		this.resetStateAndSubscribeToStore();
		this.$services.appAndFirmware.listOneFirmware({ id : this.data.id, });

		// eslint-disable-next-line no-console
		console.info('%c CREATE WbEditFirmwareModal ', 'background: blue; color: #FFF');
	}

	public destroyed(): void {
		this.unsubscribeFromStore();

		// eslint-disable-next-line no-console
		console.info('%c DESTROY WbEditFirmwareModal ', 'background: purple; color: #FFF');
	}

	public edit(): void {
		if (this.model.properties.dirtyFields.length) {
			const validation = this.$formValidation.validate(this.model);

			if (validation.ok()) {
				validation.modifiedCurrResult.id = this.data.id;
				this.$services.appAndFirmware.modifyFirmware(validation.modifiedCurrResult);
				this.isBusy = true;
			}
		}
	}

	private resetStateAndSubscribeToStore(): void {
		this.$services.appAndFirmware.resetRequestResult(); // !!! oder

		this.subscriptions.listOneFirmware = this.$services.appAndFirmware.selectedFirmware$
			.pipe(
				tap((selectedFirmwarePayload: IListOneFirmwarePayload | null) => {
					if (selectedFirmwarePayload) {
						const fields = this.model.fields;
						fields.description.value = fields.description.oldValue = selectedFirmwarePayload.description;
						fields.changeLog.value = fields.changeLog.oldValue = selectedFirmwarePayload.changeLog;
					}
				})
			).subscribe();

		this.subscriptions.editFirmware = this.$services.appAndFirmware.requestResult$
			.pipe(
				skipWhile((requestPayload) => requestPayload === null),
				tap((requestPayload: IRequestPayload | null) => {
					if (requestPayload) {
						if (requestPayload.actor === 'modifyFirmware') {
							this.$emit('close-modal');
						}
					}

					this.isBusy = false;
				})
			).subscribe();
	}

	private unsubscribeFromStore(): void {
		Object.keys(this.subscriptions).map(key => this.subscriptions[key as keyof ISubscriptions])
			.forEach((subscription: Subscription | null) => {
				if (subscription) {
					subscription.unsubscribe();
				}
			});
	}
}
