























import { Component, Prop, Watch, Vue } from 'vue-property-decorator';

interface FileToBeUploaded {
	[p: string]: {
		files: File[];
		value: string;
	};
}

@Component({})
export default class WbFileUpload extends Vue {
	@Prop({ default : null, })
	public acceptedFileTypes!: string[];

	@Prop({ default : null, })
	public defaultPreviewImage!: string;

	public imageSrc = '';
	public fileName = 'No file selected';

	private file: File | {name: string; } | 'default' = { name : '', };

	/**
	 * Public methods
	 */

	public fileUploaded(): void {
		const file = (
			this.$refs.fileUploader &&
			(this.$refs as unknown as FileToBeUploaded).fileUploader.files &&
			(this.$refs as unknown as FileToBeUploaded).fileUploader.files[0]
		)
			? (this.$refs as unknown as FileToBeUploaded).fileUploader.files[0]
			: { name : '', };

		if (
			file &&
			this.acceptedFileTypes
				.indexOf(file.name.split('.')[file.name.split('.').length - 1].toLowerCase()) > -1
		) {
			this.file = file;
			this.$emit('fileUploaded', file);
			this.imageSrc = URL.createObjectURL(file);
			this.fileName = file.name;
		} else {
			(this.$refs as unknown as FileToBeUploaded).fileUploader.value = '';
			this.$toaster.create({
				type : 'danger',
				message : `Invalid file type at ${file.name}, expected ${this.acceptedFileTypes}`,
			});
		}
	}

	public invalidPreview(): void {
		this.imageSrc = '';
	}

	/**
	 * Private methods
	 */
	@Watch('defaultPreviewImage', {
		immediate : true,
		deep : true,
	})
	private onDefaultPreviewImageChange() {
		if (this.defaultPreviewImage) {
			this.imageSrc = this.defaultPreviewImage;
			this.file = 'default';
			this.fileName = 'default image';
		}
	}
}
