





























































































































































































import { Component, Vue } from 'vue-property-decorator';
import { Subscription } from 'rxjs';
import { skipWhile, tap } from 'rxjs/operators';

import { IModel } from '@/services/common/form-validate.service';
import { PanelHeaderStatusTypes } from '@/services/common/ui.service';

import { IUser, IListUsersPayload } from '@/services/payloads/user.payload';
import { IRequestPayload } from '@/services/dtos/shared.dto';

import WbAddUserModal from './modals/wb-add-user-modal/wb-add-user-modal.vue';
import WbEditUserModal from './modals/wb-edit-user-modal/wb-edit-user-modal.vue';
import WbEditUserPrivilegesModal from './modals/wb-edit-user-privileges-modal/wb-edit-user-privileges-modal.vue';
import WbDeleteUserModal from './modals/wb-delete-user-modal/wb-delete-user-modal.vue';

interface ISubscriptions {
	listUsers: Subscription | null;
	resendConfirmationEmail: Subscription | null;
}

@Component({
	name : 'wb-admin-users',
	components : {
		WbAddUserModal,
		WbEditUserModal,
		WbEditUserPrivilegesModal,
		WbDeleteUserModal,
	},
})
export default class WbAdminUsers extends Vue {
	public panelHeaderStatusTypes = PanelHeaderStatusTypes;

	private subscriptions: ISubscriptions = {
		listUsers : null,
		resendConfirmationEmail : null,
	}

	public filterModel: IModel = {
		fields : {
			search : {
				placeholder : 'Search name',
				oldValue : '',
				value : '',
				required : false,
				focus : false,
				status : {},
				error : {},
			},
		},
		properties : {
			dirtyFields : [],
		},
	};

	public paginationOptions = {
		min : 1,
		current : 1,
		max : 1,
		pageSize : 50,
	};

	public users: IUser[] = [];

	public modal = {
		add : false,
		edit : false,
		editPrivileges : false,
		delete : false,
		allow : false,
		block : false,
		confirmationEmail : false,
	};

	public selectedUser: IUser | null = null;

	public langRoles = {
		0 : 'Super Admin',
		1 : 'Admin',
		2 : 'User',
	}

	public lang = {
		true : 'Yes',
		false : 'No',
	}

	/**
	 * CREATED
	 */
	public created(): void {
		this.subscribeToStore();
		this.listUsers();

		// eslint-disable-next-line no-console
		console.info('%c CREATE WbAdminUsers ', 'background: blue; color: #FFF');
	}

	public destroyed(): void {
		this.unsubscribeFromStore();

		// eslint-disable-next-line no-console
		console.info('%c DESTROY WbAdminUsers ', 'background: purple; color: #FFF');
	}

	// <editor-folder desc="Modals">

	public openModalAllowUser(user: IUser): void {
		this.modal.allow = true;
		this.selectedUser = user;
	}

	public openModalBlockUser(user: IUser): void {
		this.modal.block = true;
		this.selectedUser = user;
	}

	public openModalEditUser(user: IUser): void {
		this.modal.edit = true;
		this.selectedUser = user;
	}

	public openModalEditUserPrivileges(user: IUser): void {
		this.modal.editPrivileges = true;
		this.selectedUser = user;
	}

	public openModalDeleteUser(user: IUser): void {
		this.modal.delete = true;
		this.selectedUser = user;
	}

	public openModalSendConfirmationEmailToUser(user: IUser): void {
		this.modal.confirmationEmail = true;
		this.selectedUser = user;
	}

	public closeModalAddUser(): void {
		this.modal.add = false;
		this.listUsers();
	}

	public closeModalAllowUser(): void {
		this.modal.allow = false;
		this.listUsers();
	}

	public closeModalBlockUser(): void {
		this.modal.block = false;
		this.listUsers();
	}

	public closeModalEditUser(): void {
		this.modal.edit = false;
		this.listUsers();
	}

	public closeModalEditUserPrivileges(): void {
		this.modal.editPrivileges = false;
		this.listUsers();
	}

	public closeModalDeleteUser(): void {
		this.modal.delete = false;
		this.listUsers();
	}

	public closeModalSendConfirmationEmailToUser(): void {
		this.modal.confirmationEmail = false;
		this.$services.user.resetRequestResult();
	}

	// </editor-folder desc="Modals">

	public listUsers(): void {
		this.$services.user.listUsers({
			page : this.paginationOptions.current,
			pageLength : this.paginationOptions.pageSize,
			search : this.filterModel.fields.search.value.toString(),
		});
	}

	// http://localhost:5656/api/trasher

	public resendConfirmationEmail(user: IUser): void {
		this.$services.user.resendConfirmationEmail(user);
	}

	public blockUser(user: IUser): void {
		this.$services.user.blockUser(user);
	}

	public allowUser(user: IUser): void {
		this.$services.user.allowUser(user);
	}

	public sendEmailTo(users: IUser[]): void {
		const recipients: string[] = [];
		let stringRecipients = '';
		const subject = 'SUBJECT';
		const br = '%0d%0a';
		const body = `BODY OF THE EMAIL${br}${br}`;

		users.forEach((user) => {
			recipients.push(user.email);
		});

		if (recipients.length) {
			recipients.forEach((recipient) => {
				stringRecipients += recipient + ',';
			});

			const url = `mailto:?bcc=${stringRecipients}&subject=${subject}&body=${body}`;

			window.open(url);
		}
	}

	public onPaginationPageChange(newPageNumber: number): void {
		this.paginationOptions.current = newPageNumber;
		this.listUsers();
	}

	private subscribeToStore(): void {
		this.subscriptions.listUsers = this.$services.user.users$
			.pipe(
				tap((usersPayload: IListUsersPayload | null) => {
					if (usersPayload) {
						this.users = usersPayload.users;
						this.paginationOptions.max = Math.ceil(usersPayload.count / this.paginationOptions.pageSize);
					}
				})
			).subscribe();

		this.subscriptions.resendConfirmationEmail = this.$services.user.requestResult$
			.pipe(
				skipWhile((requestPayload) => requestPayload === null),
				tap((requestPayload: IRequestPayload | null) => {
					if (requestPayload && requestPayload.actor === 'resendConfirmationEmail') {
						if (requestPayload.status === 'success') {
							this.closeModalSendConfirmationEmailToUser();
						}
					}

					if (requestPayload && requestPayload.actor === 'allowUser') {
						if (requestPayload.status === 'success') {
							this.closeModalAllowUser();
						}
					}

					if (requestPayload && requestPayload.actor === 'blockUser') {
						if (requestPayload.status === 'success') {
							this.closeModalBlockUser();
						}
					}
				})
			).subscribe();
	}

	private unsubscribeFromStore(): void {
		Object.keys(this.subscriptions).map(key => this.subscriptions[key as keyof ISubscriptions])
			.forEach((subscription: Subscription | null) => {
				if (subscription) {
					subscription.unsubscribe();
				}
			});
	}
}
