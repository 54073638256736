


































import { Component, Vue } from 'vue-property-decorator';
import { ButtonStatusTypes, PanelHeaderStatusTypes } from '@/services/common/ui.service';
import WbFileUpload from '@/components/wb-file-upload/wb-file-upload.vue';
/* import { IFetchResponse } from '../../../../../../../services/common/http.service'; */

@Component({
	name : 'wb-upload-android-firmware-modal',
	components : {
		WbFileUpload,
	},
})
export default class WbUploadAndroidFirmwareModal extends Vue {
	public panelHeaderStatusTypes = PanelHeaderStatusTypes;
	public buttonStatusTypes = ButtonStatusTypes;
	public isBusy = false;

	/**
	 * CREATED
	 */
	public created(): void {
		// eslint-disable-next-line no-console
		console.info('%c CREATE WbUploadAndroidFirmwareModal ', 'background: blue; color: #FFF');
	}

	public destroyed(): void {
		// eslint-disable-next-line no-console
		console.info('%c DESTROY WbUploadAndroidFirmwareModal ', 'background: purple; color: #FFF');
	}

	public androidFirmwareChosen(file: File): void {
		this.isBusy = true;
		this.$services.androidFirmware.uploadAndroidFirmware(file).subscribe({
			next : (/* data: IFetchResponse */) => {
				/* if (data) {
					console.log(data);
				} */
				// TODO upload progress
				// TODO check if file already uploaded
			},
			error : (/* error */) => {
				this.isBusy = false;
				/* console.error(error); */
				this.$toaster.create({
					type : 'danger',
					message : 'Invalid file or already uploaded!',
				});
			},
			complete : () => {
				this.isBusy = false;
				this.$emit('close-modal');
			},
		});
	}
}
