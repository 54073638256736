










import { Component, Prop, Vue } from 'vue-property-decorator';

import {
	Chart,
	ChartConfiguration,
	Color,
	LinearScaleOptions,
	registerables
} from 'chart.js';
import { LayoutPosition } from 'chart.js/types/layout';

Chart.register(...registerables);

enum TooltipPositionTypes {
	average = 'average', // tooltip appears among the nods vertically
	nearest = 'nearest' // tooltips appears near the nods
}

enum TooltipModeTypes {
	point = 'point', // Finds all of the items that intersect the point.
	nearest = 'nearest', // Gets the item that is nearest to the point.
	index = 'index' // Finds item at the same index.
}

interface IBarChartDataSets {
	[name: string]: number[];
}

export interface IBarChartData {
	labels: string[];
	dataSets: IBarChartDataSets;
}

export interface IBar {
	[bars: string]: {
		label: string;
		borderColor?: Color | undefined;
		backgroundColor?: Color | undefined;
		hoverBorderColor?: Color | undefined;
		hoverBackgroundColor?: Color | undefined;
		borderWidth?: number;
	};
}

@Component({
	name : 'wb-bar-chart',
	components : {},
})

export default class WbBarChart extends Vue {
	private barChartInstance: Chart<'bar'> | null = null;
	private canShowLegends = true;

	@Prop({ required : false, default : '', })
	private chartName!: string;

	@Prop({ required : false, default : '', })
	private xAxisName!: string;

	@Prop({ required : false, default : '', })
	private yAxisName!: string;

	@Prop({ required : false, default : 0, })
	private width!: number;

	@Prop({ required : false, default : 0, })
	private height!: number;

	@Prop({ required : false, default : true, })
	private legend!: boolean;

	@Prop({ required : false, default : true, })
	private legendToggle!: boolean;

	@Prop({ required : false, default : 'bottom', })
	private legendPosition!: LayoutPosition;

	@Prop({ required : false, default : 10, })
	private legendLabelsPadding!: number;

	@Prop({ required : false, default : 11, })
	private xTicksLimit!: number;

	@Prop({ required : false, default : 11, })
	private yTicksLimit!: number;

	@Prop({ required : false, default : '10%', })
	private yGrace!: string;

	@Prop({ required : false, default : true, })
	private yBeginAtZero!: boolean;

	@Prop({ required : false, default : 1, })
	private padding!: number;

	@Prop({ required : true, default : null, })
	private data!: IBarChartData | null;

	@Prop({ required : true, default : null, })
	private bars!: IBar | null;

	private chartConfig: ChartConfiguration<'bar'> = {
		type : 'bar',
		data : {
			labels : [],
			datasets : [],
		},
		options : {
			responsive : true,
			maintainAspectRatio : false,
			plugins : {
				title : {
					display : true,
					align : 'center',
					text : '',
				},
				legend : {
					position : 'bottom',
					display : true,
					labels : {
						padding : 10,
					},
				},
				tooltip : {},
			},

			layout : {
				padding : 0,
			},

			scales : {
				x : {
					title : {
						display : true,
						text : '',
					},
					ticks : {
						maxRotation : 0,
						maxTicksLimit : 11,
					},
				},
				y : {
					title : {
						display : true,
						text : '',
					},
					ticks : {
						maxTicksLimit : 11,
					},
					beginAtZero : this.yBeginAtZero,
					grace : '10%',
				},
			},
		},
	};

	private isInitialized = false;
	private chartCreationCounter = 0;

	public style = {
		width : '100%',
		height : '100%',
	}

	public dimensionError = false;

	/**
	 * CREATED
	 */

	public mounted(): void {
		// view-source:https://www.chartjs.org/docs/latest/samples/bar/vertical.html
		this.initChart();

		// eslint-disable-next-line no-console
		console.info('%c CREATE WbBarChart', 'background: blue; color: #FFF');
	}

	public destroyed(): void {
		// eslint-disable-next-line no-console
		console.info('%c DESTROY WbBarChart', 'background: purple; color: #FFF');
	}

	private setBeginAtZero(): void {
		if (this.yBeginAtZero !== undefined && this.chartConfig && this.chartConfig.options && this.chartConfig.options.scales && this.chartConfig.options.scales.y) {
			const axis = (this.chartConfig.options.scales.y as LinearScaleOptions);
			axis.beginAtZero = this.yBeginAtZero;
		}
	}

	private setGrace(): void {
		if (this.yGrace && this.chartConfig && this.chartConfig.options && this.chartConfig.options.scales && this.chartConfig.options.scales.y) {
			const axis = (this.chartConfig.options.scales.y as LinearScaleOptions);
			axis.grace = this.yGrace;
		}
	}

	private setTicksLimit(): void {
		if (this.xTicksLimit && this.chartConfig && this.chartConfig.options && this.chartConfig.options.scales && this.chartConfig.options.scales.x) {
			const ticks = (this.chartConfig.options.scales.x as LinearScaleOptions).ticks;
			ticks.maxTicksLimit = this.xTicksLimit;
		}
		if (this.yTicksLimit && this.chartConfig && this.chartConfig.options && this.chartConfig.options.scales && this.chartConfig.options.scales.y) {
			const ticks = (this.chartConfig.options.scales.y as LinearScaleOptions).ticks;
			ticks.maxTicksLimit = this.yTicksLimit;
		}
	}

	private processConfigChartDimensions(): void {
		if (this.width !== undefined && this.width > 0) {
			this.style.width = `${this.width}px`;
		}
		if (this.height !== undefined && this.height > 0) {
			this.style.height = `${this.height}px`;
		}
	}

	private setChartIsInitialized(state: boolean): void {
		this.isInitialized = state;
	}

	private getChartIsInitialized(): boolean {
		return this.isInitialized;
	}

	private processConfigChartTitle(): void {
		if (this.chartConfig && this.chartConfig.options && this.chartConfig.options.plugins) {
			if (this.chartName !== undefined) {
				const title = this.chartConfig.options.plugins.title;
				if (title) {
					title.display = true;
					title.text = '' + this.chartName;
				}
			}

			if (this.chartName === '') {
				const title = this.chartConfig.options.plugins.title;
				if (title) {
					title.display = false;
				}
			}

			if (this.xAxisName !== undefined) {
				if (this.chartConfig && this.chartConfig.options && this.chartConfig.options.scales && this.chartConfig.options.scales.x) {
					const title = this.chartConfig.options.scales.x.title;
					if (title) {
						title.display = true;
						title.text = '' + this.xAxisName;
					}
				}
			}

			if (this.yAxisName !== undefined) {
				if (this.chartConfig && this.chartConfig.options && this.chartConfig.options.scales && this.chartConfig.options.scales.y) {
					const title = this.chartConfig.options.scales.y.title;
					if (title) {
						title.display = true;
						title.text = '' + this.yAxisName;
					}
				}
			}
		}
	}

	private processConfigChartData(): void {
		if (this.chartConfig && this.chartConfig.data && this.chartConfig.data.datasets && this.data && this.data.dataSets) {
			const allowedKeys = this.data.dataSets && Object.keys(this.data.dataSets);

			this.chartConfig.data.labels = this.data.labels;
			this.chartConfig.data.datasets = [];

			this.bars && Object.keys(this.bars).forEach((key) => {
				if (allowedKeys && allowedKeys.indexOf(key) > -1) {
					if (this.bars && this.data && this.data.dataSets) {
						this.chartConfig.data.datasets.push({
							label : this.bars[key].label || '',
							data : this.data.dataSets[key].map((item) => +item) as number[],
							backgroundColor : this.bars[key].backgroundColor || this.bars[key].borderColor,
							hoverBackgroundColor : this.bars[key].hoverBackgroundColor || this.bars[key].hoverBorderColor,
							borderColor : this.bars[key].borderColor || 'black',
							hoverBorderColor : this.bars[key].hoverBorderColor || this.bars[key].borderColor,
							borderWidth : this.bars[key].borderWidth || 2,
						});
					}
				}
			});
		}
	}

	private processConfigChartPadding(): void {
		if (this.chartConfig && this.chartConfig.options) {
			const layout = this.chartConfig.options.layout;
			if (layout) {
				layout.padding = this.padding;
			}
		}
	}

	private processConfigChartLegend(): void {
		// Hide legend
		if (this.chartConfig && this.chartConfig.options && this.chartConfig.options.plugins &&
			this.legend !== undefined && !this.legend) {
			const legend = this.chartConfig.options.plugins.legend;
			if (legend) {
				legend.display = false;
			}
		}

		// Set legend position
		if (this.legendPosition && this.canShowLegends && this.chartConfig && this.chartConfig.options && this.chartConfig.options.plugins) {
			const legend = this.chartConfig.options.plugins.legend || {};
			if (legend.position) {
				legend.position = this.legendPosition;
			}
		}

		if (this.chartConfig && this.chartConfig.options && this.chartConfig.options.plugins &&
			this.legendToggle !== undefined && !this.legendToggle) {
			const legend = this.chartConfig.options.plugins.legend;
			if (legend) {
				legend.onClick = () => {
					// do nothing
				};
			}
		} else {
			if (this.chartConfig && this.chartConfig.options && this.chartConfig.options.plugins) {
				const legend = this.chartConfig.options.plugins.legend;
				if (legend) {
					legend.onClick = (e, legendItem, legend) => {
						const lci = this.barChartInstance;
						const index = legendItem.datasetIndex;

						// toggle
						if (lci) {
							lci.data.datasets.forEach((e, i) => {
								if (i === index) {
									const meta = lci.getDatasetMeta(i);
									if (meta.hidden) {
										meta.hidden = false;
									} else {
										let visibleCounter = 0;
										if (legend && legend.legendItems) {
											legend.legendItems.forEach((legendItem) => {
												if (legendItem.hidden === false) {
													visibleCounter++;
												}
											});
											if (visibleCounter > 1) {
												meta.hidden = true;
											}
										}
									}
								}
							});

							// update the chart
							lci.update();
						}
					};
				}
			}
		}
	}

	private processConfigChartLegendLabelsPadding(): void {
		// Set Legend Labels Padding
		if (
			this.chartConfig && this.chartConfig.options && this.chartConfig.options.plugins && this.chartConfig.options.plugins.legend &&
			this.legendLabelsPadding && this.canShowLegends
		) {
			const labels = this.chartConfig.options.plugins.legend.labels || {};
			if (labels) {
				labels.padding = this.legendLabelsPadding;
			}
		}
	}

	private processConfigChartTooltips(): void {
		// http://www.chartjs.org/docs/latest/configuration/tooltip.html
		// https://www.chartjs.org/docs/latest/samples/tooltip/interactions.html
		if (this.chartConfig && this.chartConfig.options) {
			const plugins = this.chartConfig.options.plugins;

			if (plugins) {
				plugins.tooltip = {
					mode : TooltipModeTypes.index,
					position : TooltipPositionTypes.nearest,
					intersect : false, // If intersect is false shows the nearest or average tooltip if the mouse cursor on the chart.
					// If intersect is true shows the nearest or average tooltip if the mouse on the chart node.
				};
			}
		}
	}

	private createChartInstance(): void {
		if (this.$refs) {
			if (this.getChartIsInitialized() && this.barChartInstance !== null) {
				this.barChartInstance.destroy();
				this.setChartIsInitialized(false);
			}

			const ctx = this.$refs.canvas as HTMLCanvasElement;
			this.barChartInstance = new Chart<'bar'>(ctx, this.chartConfig);
			this.setChartIsInitialized(true);
		}
	}

	private checkParentDimensionsIsOkayAndInit(): void {
		this.chartCreationCounter += 1;
		let parentHeight = 0;
		let parentWidth = 0;

		if (this.$refs && this.$refs.canvas) {
			const parentElement = (this.$refs.canvas as HTMLCanvasElement).parentElement;

			if (parentElement) {
				parentHeight = parentElement.getBoundingClientRect().height;
				parentWidth = parentElement.getBoundingClientRect().width;
			}

			if (parentHeight > 0 && parentWidth > 0) {
				this.createChartInstance();
			} else if (this.chartCreationCounter < 100) {
				setTimeout(() => {
					this.checkParentDimensionsIsOkayAndInit();
				}, 10);
			} else {
				this.style.width = '200px';
				this.style.height = '100px';
				this.dimensionError = true;
			}
		}
	}

	private initChart(): void {
		this.setBeginAtZero();
		this.setGrace();
		this.setTicksLimit();
		this.processConfigChartDimensions();
		this.processConfigChartPadding();
		this.processConfigChartTooltips();
		this.processConfigChartData();
		this.processConfigChartTitle();
		this.processConfigChartLegend();
		this.processConfigChartLegendLabelsPadding();

		// Here we need check the parent dimensions, because
		// the canvas inherits it from the parent dom element.
		// If the parent dimension is bigger than 0, the chart
		// going to be initialized. Other way we try in a
		// recursion every 10 milliseconds.

		this.chartCreationCounter = 0;
		this.checkParentDimensionsIsOkayAndInit();
	}
}
