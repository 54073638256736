


































































































































import { Component, Vue } from 'vue-property-decorator';
import { Subscription } from 'rxjs';
import { skipWhile, tap } from 'rxjs/operators';

import { PanelHeaderStatusTypes } from '@/services/common/ui.service';
import { IModel } from '@/services/common/form-validate.service';

import { IUser, IListUsersPayload } from '@/services/payloads/user.payload';
import { IRequestPayload } from '@/services/dtos/shared.dto';

import WbPaymentHistory from '../../../_components/wb-payment-history/wb-payment-history.vue';
import WbTopUpBalanceModal from '../modals/wb-top-up-balance-modal/wb-top-up-balance-modal.vue';
import WbWithdrawBalanceModal from '../modals/wb-withdraw-balance-modal/wb-withdraw-balance-modal.vue';

interface ISubscriptions {
	listUsersBalance: Subscription | null;
	topUp: Subscription | null;
}

@Component({
	name : 'wb-admin-balance',
	components : {
		WbPaymentHistory,
		WbTopUpBalanceModal,
		WbWithdrawBalanceModal,
	},
})
export default class WbAdminBalance extends Vue {
	public panelHeaderStatusTypes = PanelHeaderStatusTypes;

	private subscriptions: ISubscriptions = {
		listUsersBalance : null,
		topUp : null,
	};

	public filterModel: IModel = {
		fields : {
			search : {
				placeholder : 'Search name',
				oldValue : '',
				value : '',
				required : false,
				focus : false,
				status : {},
				error : {},
			},
		},
		properties : {
			dirtyFields : [],
		},
	};

	public paginationOptions = {
		min : 1,
		current : 1,
		max : 1,
		pageSize : 25,
	};

	public users: IUser[] = [];

	public selectedUser: IUser | null = null;
	public selectedUserRowId: number | null = null;

	public modal = {
		topUp : false,
		withdraw : false,
	};

	public langRoles = {
		0 : 'Super Admin',
		1 : 'Admin',
		2 : 'User',
	}

	public lang = {
		true : 'Yes',
		false : 'No',
	}

	/**
	 * CREATED
	 */
	public created(): void {
		this.subscribeToStore();
		this.listUsersBalance();

		// eslint-disable-next-line no-console
		console.info('%c CREATE WbAdminBalance ', 'background: blue; color: #FFF');
	}

	public destroyed(): void {
		this.unsubscribeFromStore();

		// eslint-disable-next-line no-console
		console.info('%c DESTROY WbAdminBalance ', 'background: purple; color: #FFF');
	}

	public listUsersBalance(): void {
		this.$services.user.listUsersBalance({
			page : this.paginationOptions.current,
			pageLength : this.paginationOptions.pageSize,
			search : this.filterModel.fields.search.value.toString(),
		});
	}

	public onPaginationPageChange(newPageNumber: number): void {
		this.paginationOptions.current = newPageNumber;
		this.listUsersBalance();
	}

	/* Modals */

	public openModalTopUpBalance(user: IUser): void {
		this.modal.topUp = true;
		this.selectedUser = user;
	}

	public openModalWithdrawBalance(user: IUser): void {
		this.modal.withdraw = true;
		this.selectedUser = user;
	}

	public closeModalTopUpBalance(): void {
		this.modal.topUp = false;
		this.listUsersBalance();
	}

	public closeModalWithdrawBalance(): void {
		this.modal.withdraw = false;
		this.listUsersBalance();
	}

	/* Modals */

	private subscribeToStore(): void {
		this.subscriptions.listUsersBalance = this.$services.user.usersBalance$
			.pipe(
				tap((usersPayload: IListUsersPayload | null) => {
					if (usersPayload) {
						this.users = usersPayload.users;
						this.paginationOptions.max = Math.ceil(usersPayload.count / this.paginationOptions.pageSize);
					}
				})
			).subscribe();

		this.subscriptions.topUp = this.$services.balance.requestResult$
			.pipe(
				skipWhile((requestPayload) => requestPayload === null),
				tap((requestPayload: IRequestPayload | null) => {
					if (requestPayload && requestPayload.actor === 'resendConfirmationEmail') {
						if (requestPayload.status === 'success') {
							// this.closeModalSendConfirmationEmailToUser();
						}
					}
				})
			).subscribe();
	}

	private unsubscribeFromStore(): void {
		Object.keys(this.subscriptions).map(key => this.subscriptions[key as keyof ISubscriptions])
			.forEach((subscription: Subscription | null) => {
				if (subscription) {
					subscription.unsubscribe();
				}
			});
	}

	private setSelectedRowUserId(userId: number): void {
		this.selectedUserRowId = this.selectedUserRowId === userId ? null : userId;
	}
}
